import Cookies from 'js-cookie';
import { users } from '../services/user-service';

const getAuthorizationToken = async () => {
  const token = await Cookies.get('Authorization') || '';
  if (token) {
    return token;
  }
  else {
    await users.refreshToken().then((res:any)=>{
      return res;
    }).catch(() => {
      return '';
    });
    return '';
  }
};
const removeAuthorizationToken = async () => {
  await Cookies.remove('Authorization');
};
const getRefreshToken = () => {
  return Cookies.get('refresh') || '';
};
const ApiHeaderOptions = async (content_type?: "application/json") => {
  return {
    headers: {
      'Content-Type': content_type,
      'Authorization': await getAuthorizationToken(),
    }
  }
}
export { ApiHeaderOptions, getAuthorizationToken, getRefreshToken,removeAuthorizationToken };