import { useLanguage } from "@/components/context/LanguageContext";
import LoadingIcon from "@/components/loading/loading-icon";
import LoadingScreen from "@/components/loading/loading-screen";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardTitle, CardDescription, CardFooter } from "@/components/ui/card";
import { activateAccount } from "@/services/user-service";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ActivateAccount = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [userInfo, setUserInfo ] = useState<any>({
        uid: '',
        token: '',
        email: '',
    });
    const [needToActivate, setNeedToActivate] = useState(true);

    useEffect(() => {
        getParams();
        document.title = 'Lazem GO - Activate Account'
    }, []);
    
    const getParams = () => {
        const search = new URLSearchParams(location.search);
        const uid = search.get('uid');
        const token = search.get('token');
        const email = search.get('email');
        if (uid && token) {
            setUserInfo({
                ...userInfo,
                uid: uid || '',
                token: token || '',
                email: email || '',
            });
        } else {
            navigate('/login');
        }
    }

    const handleActivateAccount = async () => {
        setLoading(true);
        let data = {
            uid: userInfo.uid,
            token: userInfo.token,
        }
        await activateAccount(data).then(() => {
            setNeedToActivate(false);
        }).catch((error) => {
            console.log('Error', error);
        }).finally(() => {
            setLoading(false);
        });
    }
    
    return (
        <div
            dir={language === 'ar' ? 'rtl' : 'ltr'}
        >
            {
                loading && <LoadingScreen />
            }
            {
                !needToActivate ?
                    <Card
                        className="w-96 m-auto mt-20 p-4"
                    >
                        <CardContent>
                        <div
                            className="flex items-center justify-center w-16 h-16 bg-green-500 rounded-full mx-auto my-6"
                        >
                            <svg 
                                viewBox="0 0 1024 1024" 
                                xmlns="http://www.w3.org/2000/svg" 
                                fill="#000000"
                                width={64}
                            >
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#0d750b" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"></path></g>
                            </svg>
                        </div>
                            <CardTitle>
                                {language === 'en' ? 'Account activated successfully' : 'تم تنشيط الحساب بنجاح'}
                            </CardTitle>
                        </CardContent>
                        <CardFooter
                            className="flex justify-center"
                        >
                            <Button onClick={() => navigate('/login')}>
                                {language === 'en' ? 'Login' : 'تسجيل الدخول'}
                            </Button>
                        </CardFooter>
                    </Card>
                :
                <Card
                    className="w-96 m-auto mt-20 p-4"
                >
                    <CardContent>
                        <CardTitle
                            className="text-center my-4"
                        >
                            {language === 'en' ? 'Activate Account' : 'تنشيط الحساب'}
                        </CardTitle>
                        <CardDescription>
                            {language === 'en' ? 'Click the button below to activate your account' : 'انقر على الزر أدناه لتنشيط حسابك'}
                        </CardDescription>
                    </CardContent>
                    <CardFooter
                        className="flex justify-center"
                    >
                        <Button 
                            onClick={handleActivateAccount}
                            disabled={loading}
                        >
                            {loading && <LoadingIcon />}
                            {language === 'en' ? 'Activate Account' : 'تنشيط الحساب'}
                        </Button>
                    </CardFooter>
                </Card>

            }
        </div>
    );
};

export default ActivateAccount;