import { useLanguage } from "@/components/context/LanguageContext";
import LoadingIcon from "@/components/loading/loading-icon";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardTitle, CardDescription, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { resetPassword } from "@/services/user-service";
import { Label } from "@radix-ui/react-label";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const { language, changeLanguage } = useLanguage();
    const frontEndUrl = process.env.FRONTEND_URL || "go.lazem.sa" ;
    const [userInfo, setUserInfo] = useState({
        email: '',
        website_name:"Lazem GO",
        website_url: frontEndUrl + "/set-new-password/?email=",
    });
    const [stage, setStage] = useState(0);
    const [error, setError] = useState('');
    const navigate  = useNavigate();
    const [loading, setLoading] = useState(false);
    const handleChange = (e:any) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        document.title = 'Lazem GO - Forgot Password';
    } , []);

    const handleForgotPassword = async () => {
        if (checkFields()) {
            setLoading(true);
            let data = {
                email: userInfo.email,
                website_name: userInfo.website_name,
                website_url: userInfo.website_url + userInfo.email + '&&',
            }
            await resetPassword(data).then((response) => {
                setStage(1);
            }).catch((error) => {
                if (error.response){
                    console.log('Error', error.response.data);
                    let messages:any = []
                    Object.keys(error.response.data).forEach((key) => {
                        messages.push(error.response.data[key]);
                    });
                    if (messages.length > 0) {
                        if (messages[0] === 'User with given email does not exist.') {
                            setError(language === 'en' ? 'email does not exist or not activated' : 'البريد الإلكتروني غير موجود أو غير مفعل');
                        }
                    }
                }
                else {
                    setError(language === 'en' ? 'Something went wrong' : 'حدث خطأ ما');
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const checkEmail = () => {
        if (!userInfo.email.endsWith('@lazem.sa')) {
          setError(language === 'en' ? 'Please use Lazem email' : 'يرجى استخدام بريد Lazem');
          return false;
        }
        return true;
      }

    const checkFields = () => {
        if (!userInfo.email) {
            setError(language === 'en' ? 'Please fill all fields' : 'يرجى ملء جميع الحقول');
            return false;
        }
        if (!checkEmail()) {
            setError(language === 'en' ? 'Please use Lazem email' : 'يرجى استخدام بريد Lazem');
            return false;
        }
        setError('');
        return true;
    }

    return (
        <div
            dir={language === 'en' ? 'ltr' : 'rtl'}
        >
            <Card 
                className="w-96 mx-auto p-4 mt-0 lg:mt-40 md:mt-20 h-screen max-h-screen flex flex-col justify-center lg:h-auto md:h-auto"
            >
                <CardContent
                    className="flex flex-col text-start"
                >
                    <div
                        className="flex justify-between"
                    >
                        <Button 
                            onClick={() => navigate('/login')}
                            className="self-start"
                            variant={"secondary"}
                        >
                            {language === 'en' ? 'Back to Login' : 'العودة إلى تسجيل الدخول'}
                        </Button>
                        <Button
                            onClick={()=>changeLanguage(language === 'en' ? 'ar' : 'en')}
                            className="self-end"
                            variant={"secondary"}
                        >
                            {language === 'en' ? 'العربية' : 'English'}
                        </Button>
                    </div>
                    <CardTitle
                        className="font-bold text-center my-4 mt-8"
                    >
                        {language === 'en' ? 'Reset Password' : 'إعادة تعيين كلمة المرور'}
                    </CardTitle>
                    {stage === 0 ? (
                        <>
                            <CardDescription
                                className="text-center mb-4"
                            >
                                {language === 'en' ? 'Please enter your email to reset your password' : 'يرجى إدخال بريدك الإلكتروني لإعادة تعيين كلمة المرور'}
                            </CardDescription>
                            <Label>{language === 'en' ? 'Email' : 'البريد الإلكتروني'}</Label>
                            <Input name="email" value={userInfo.email} onChange={handleChange} />
                            <Button 
                                onClick={handleForgotPassword}
                                disabled={loading}
                                className="w-full mt-4"
                            >
                                    {loading && <LoadingIcon />}
                                    {language === 'en' ? 'Reset Password' : 'إعادة تعيين كلمة المرور'}
                            </Button>
                        </>
                    ) : (
                        <>
                            <CardDescription
                                className="text-center my-4 text-xl"
                            >
                                {language === 'en' ? 'Password reset instructions sent to your email' : 'تم إرسال تعليمات إعادة تعيين كلمة المرور إلى بريدك الإلكتروني'}
                            </CardDescription>
                            <Button onClick={() => navigate('/login')}>{language === 'en' ? 'Back to Login' : 'العودة إلى تسجيل الدخول'}</Button>
                        </>
                    )}
                    {error && 
                        <CardFooter
                            className="text-red-500 font-bold text-start mt-4"
                        >
                            {error}
                        </CardFooter>
                    }
                    
                </CardContent>
            </Card>
        </div>
    );
};

export default ForgotPassword;