import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import {
  EmailTemplates,
  SystemNotifications,
} from "@/services/notification-services";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { MdDelete } from "react-icons/md";
import { useAuth } from "@/components/context/AuthContext";
import { c } from "node_modules/vite/dist/node/types.d-aGj9QkWt";

const SystemNotificationDashboard = () => {
  const { language } = useLanguage();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [newSystemNotificationsTitle, setNewSystemNotificationsTitle] =
    useState({
      title: "",
      email_template: "",
      trigger: "",
    });

  const { checkPermission } = useAuth();
  const canDeleteSystemNotification = checkPermission("delete_systemsnotifications");
  const canUpdateSystemNotification = checkPermission("change_systemnotifications");
  const canCreateSystemNotification = checkPermission("add_systemnotifications");

  const {
    data: systemNotificationsTriggerData,
    isLoading: systemNotificationsTriggerLoading,
  } = useQuery({
    queryKey: ["systemNotificationsTrigger"],
    queryFn: () => SystemNotifications.triggers(),
    refetchOnWindowFocus: false,
  });

  const {
    data: systemNotificationsData,
    isLoading: systemNotificationsLoading,
    isFetching: systemNotificationsFetching,
    refetch: systemNotificationsRefetch,
  } = useQuery({
    queryKey: ["systemNotifications"],
    queryFn: () => SystemNotifications.list(),
    refetchOnWindowFocus: false,
  });

  const { data: emailTemplatesData, isLoading: emailTemplatesLoading } =
    useQuery({
      queryKey: ["emailTemplates"],
      queryFn: () => EmailTemplates.list(),
      refetchOnWindowFocus: false,
    });

  const checkInputs = () => {
    if (newSystemNotificationsTitle.title === "") {
      setFormError(
        language === "en" ? "Please enter the title" : "الرجاء إدخال العنوان"
      );
      window.scrollTo(0, 0);
      return false;
    }
    if (newSystemNotificationsTitle.trigger === "") {
      setFormError(
        language === "en" ? "Please select the trigger" : "الرجاء تحديد المشغل"
      );
      window.scrollTo(0, 0);
      return false;
    }
    if (newSystemNotificationsTitle.email_template === "") {
      setFormError(
        language === "en"
          ? "Please select the email template"
          : "الرجاء تحديد قالب البريد الإلكتروني"
      );
      window.scrollTo(0, 0);
      return false;
    }
    return true;
  };

  const handleDeleteNotification = (id: string) => {
    setIsLoading(true);
    SystemNotifications.delete(id)
      .then(() => {
        systemNotificationsRefetch();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const handleUpdateSystemNotification = (id: string) => {
    let field = systemNotificationsData.find((item: any) => item.id === id);
    if (
      field.title === "" ||
      field.trigger === "" ||
      field.email_template === ""
    ) {
      setFormError(
        language === "en" ? "Please fill all fields" : "الرجاء ملء جميع الحقول"
      );
      window.scrollTo(0, 0);
      return;
    }
    field = {
      title: field.title,
      trigger: field.trigger,
      email_template: field.email_template?.id || field.email_template,
    };
    setIsLoading(true);
    SystemNotifications.update(id, field)
      .then(() => {
        systemNotificationsRefetch();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleCreateSystemNotification = () => {
    if (!checkInputs()) {
      return;
    }
    setIsLoading(true);
    SystemNotifications.create(newSystemNotificationsTitle)
      .then(() => {
        setNewSystemNotificationsTitle({
          title: "",
          email_template: "",
          trigger: "",
        });
        systemNotificationsRefetch();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {(systemNotificationsLoading ||
        emailTemplatesLoading ||
        systemNotificationsTriggerLoading ||
        systemNotificationsFetching) && <LoadingScreen />}

      <div className="flex justify-content-center mt-10 mx-10">
        <Button
          onClick={() => navigate("/system-notification/emails")}
          className=""
          permission="view_emailtemplates"
        >
          {language === "en" ? "Email Templates" : "قوالب البريد الإلكتروني"}
        </Button>
        <Button
          onClick={() => navigate("/")}
          className="mx-4"
          variant={"secondary"}
        >
          {language === "en"
            ? "Home"
            : "الصفحة الرئيسية"}
        </Button>
      </div>

      <div>
        <h1 className="text-xl font-bold text-center my-10">
          {language === "en" ? "System Notifications" : "إشعارات النظام"}
        </h1>
        {formError && (
          <p
            className="text-red-500 text-lg text-center my-4 font-bold"
          >
            {formError}
          </p>
        )}
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="text-start">
              {language === "en" ? "Title" : "العنوان"}
            </TableHead>
            <TableHead className="text-start">
              {language === "en" ? "Trigger" : "المشغل"}
            </TableHead>
            <TableHead className="text-start">
              {language === "en" ? "Email Template" : "قالب البريد الإلكتروني"}
            </TableHead>
            <TableHead className="text-start">
              {language === "en" ? "Actions" : "الإجراءات"}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {systemNotificationsData &&
            systemNotificationsData.map((systemNotification: any) => (
              <TableRow key={systemNotification.id}>
                <TableCell>
                  <Input
                    type="text"
                    placeholder={language === "en" ? "Title" : "العنوان"}
                    defaultValue={systemNotification.title}
                    onChange={(e) => {
                      queryClient.setQueryData(
                        ["systemNotifications"],
                        (old: any) => {
                          return old.map((item: any) => {
                            if (item.id === systemNotification.id) {
                              return {
                                ...item,
                                title: e.target.value,
                              };
                            }
                            return item;
                          });
                        }
                      );
                    }}
                    disabled={!canUpdateSystemNotification}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    onValueChange={(value) => {
                      queryClient.setQueryData(
                        ["systemNotifications"],
                        (old: any) => {
                          return old.map((item: any) => {
                            if (item.id === systemNotification.id) {
                              return {
                                ...item,
                                trigger: value,
                              };
                            }
                            return item;
                          });
                        }
                      );
                    }}
                    defaultValue={systemNotification.trigger}
                    disabled={!canUpdateSystemNotification}
                  >
                    <SelectTrigger>
                      <SelectValue
                        placeholder={
                          language === "en" ? "Select Trigger" : "اختر المشغل"
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {systemNotificationsTriggerData &&
                          systemNotificationsTriggerData.map((trigger: any) => (
                            <SelectItem
                              key={trigger.value}
                              value={trigger.value}
                              disabled={systemNotificationsData?.some(
                                (item: any) => item.trigger === trigger.value
                              )}
                            >
                              {trigger.display_name}
                            </SelectItem>
                          ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    onValueChange={(value) => {
                      queryClient.setQueryData(
                        ["systemNotifications"],
                        (old: any) => {
                          return old.map((item: any) => {
                            if (item.id === systemNotification.id) {
                              return {
                                ...item,
                                email_template: value,
                              };
                            }
                            return item;
                          });
                        }
                      );
                    }}
                    defaultValue={systemNotification.email_template.id}
                    disabled={!canUpdateSystemNotification}
                  >
                    <SelectTrigger>
                      <SelectValue
                        placeholder={
                          language === "en"
                            ? "Select Email Template"
                            : "اختر قالب البريد الإلكتروني"
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {emailTemplatesData &&
                          emailTemplatesData.map((emailTemplate: any) => (
                            <SelectItem
                              key={emailTemplate.id}
                              value={emailTemplate.id}
                            >
                              {emailTemplate.title}
                            </SelectItem>
                          ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </TableCell>
                <TableCell>
                  <div
                    className="flex items-center gap-4 w-fit"
                  >
                    <Button
                      onClick={() => {
                        handleUpdateSystemNotification(systemNotification.id);
                      }}
                      disabled={isLoading}
                      permission="change_systemnotifications"
                    >
                      {isLoading ? (
                        <LoadingIcon />
                      ) : language === "en" ? (
                        "Save"
                      ) : (
                        "حفظ"
                      )}
                    </Button>
                    <Button
                      onClick={() => {
                        handleDeleteNotification(systemNotification.id);
                      }}
                      disabled={isLoading}
                      variant="destructive"
                      permission="delete_systemnotifications"
                    >
                      <MdDelete
                        size={20}
                      />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          {canCreateSystemNotification &&
            <TableRow>
              <TableCell>
                <Input
                  type="text"
                  placeholder={language === "en" ? "Title" : "العنوان"}
                  value={newSystemNotificationsTitle.title}
                  onChange={(e) =>
                    setNewSystemNotificationsTitle({
                      ...newSystemNotificationsTitle,
                      title: e.target.value,
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <Select
                  onValueChange={(value) => {
                    setNewSystemNotificationsTitle({
                      ...newSystemNotificationsTitle,
                      trigger: value,
                    });
                  }}
                  value={newSystemNotificationsTitle.trigger}
                >
                  <SelectTrigger>
                    <SelectValue
                      placeholder={
                        language === "en" ? "Select Trigger" : "اختر المشغل"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {systemNotificationsTriggerData &&
                        systemNotificationsTriggerData.map((trigger: any) => (
                          <SelectItem
                            key={trigger.value}
                            value={trigger.value}
                            disabled={systemNotificationsData?.some(
                              (item: any) => item.trigger === trigger.value
                            )}
                          >
                            {trigger.display_name}{" "}
                            {systemNotificationsData?.some(
                              (item: any) => item.trigger === trigger.value
                            ) &&
                              (language === "en"
                                ? " (Already used)"
                                : " (مستخدم بالفعل)")}
                          </SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  onValueChange={(value) => {
                    setNewSystemNotificationsTitle({
                      ...newSystemNotificationsTitle,
                      email_template: value,
                    });
                  }}
                  value={newSystemNotificationsTitle.email_template}
                >
                  <SelectTrigger>
                    <SelectValue
                      placeholder={
                        language === "en"
                          ? "Select Email Template"
                          : "اختر قالب البريد الإلكتروني"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {emailTemplatesData &&
                        emailTemplatesData.map((emailTemplate: any) => (
                          <SelectItem
                            key={emailTemplate.id}
                            value={emailTemplate.id}
                          >
                            {emailTemplate.title}
                          </SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </TableCell>
              <TableCell>
                <Button
                  onClick={handleCreateSystemNotification}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <LoadingIcon />
                  ) : language === "en" ? (
                    "Create"
                  ) : (
                    "إنشاء"
                  )}
                </Button>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </div>
  );
};

export default SystemNotificationDashboard;
