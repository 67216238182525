import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { users } from "@/services/user-service";
import LoadingScreen from "@/components/loading/loading-screen";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";

const UsersManagement = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [filteredUsers, setFilteredUsers] = useState<any>([]);

  useEffect(() => {
    document.title = "Lazem GO - Users Management";
  }, []);

  const {
    data: usersData,
    isLoading: usersLoading,
  } = useQuery({
    queryKey: ["users management"],
    queryFn: () => {
      return users
        .simple()
        .then((response) => {
          setFilteredUsers(response.data);
          return response.data;
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    refetchOnWindowFocus: false,
  });

  const handleUsersFilter = (name: string) => {
    const filtered = usersData?.filter((user: any) =>
      user?.full_name?.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredUsers(filtered || []);
  };
  

  return (
    <div dir={language === "en" ? "ltr" : "rtl"} className="p-4">
      {usersLoading && <LoadingScreen />}
      {
        <div>
          <div className="my-6">
            <h1 className="text-2xl font-bold">
              {language === "en" ? "Users Management" : "إدارة المستخدمين"}
            </h1>

            <div className="flex justify-start items-center gap-6 mt-4 mx-6">
              <Button
                onClick={() => navigate("/organization")}
                className="w-fit"
                variant={"secondary"}
              >
                {language === "en" ? "Back" : "العودة"}
              </Button>
            </div>

            <div 
              className="flex justify-between items-center mt-4 mx-6"
            >
              <Input
                placeholder={
                  language === "en" ? "Search by name" : "البحث بالاسم"
                }
                onChange={(e) => handleUsersFilter(e.target.value)}
                className="w-full md:w-1/2 lg:w-1/3"
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4 justify-center">
            {filteredUsers?.map((user: any) => (
              <Card key={user.id} className="w-64">
                <CardHeader className="min-h-32">
                  <CardTitle className="text-lg font-bold capitalize">
                    {user.full_name}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p>{user.email}</p>
                </CardContent>
                <CardFooter>
                  <Button
                    onClick={() =>
                      navigate(`/organization/users/id/${user.id}`)
                    }
                  >
                    {language === "en" ? "View" : "عرض"}
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>
      }
    </div>
  );
};

export default UsersManagement;
