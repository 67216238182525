import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"
import { useNavigate } from "react-router-dom";

const NotAuthorized = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();

    return (
        <div
            className="flex justify-content-center align-items-center flex-wrap h-screen"
        >
            <Card
                className="m-3 w-64 h-auto flex flex-col justify-content-center align-items-center m-auto"
            >
                <CardHeader
                    className="h-1/3 w-full flex justify-content-center align-items-center m-auto"
                >
                    <CardTitle
                        className="text-2xl font-bold flex justify-content-center align-items-center m-auto"
                    >
                        {language === 'en' ? 'You are not authorized to view this page' : 'غير مصرح لك بعرض هذه الصفحة'}

                    </CardTitle>
                </CardHeader>
                <CardContent
                    className="h-1/3 w-full flex justify-content-center align-items-center m-auto"
                >
                    <CardDescription
                        className="text-lg font-medium flex justify-content-center align-items-center m-auto"
                    >
                    </CardDescription>
                </CardContent>
                <CardFooter
                    className="h-1/3 w-full flex justify-content-center align-items-center m-auto"
                >
                    <CardDescription
                        className="text-lg font-medium flex justify-content-center align-items-center m-auto"
                    >
                    </CardDescription>
                    <div
                        className="flex flex-col justify-content-center align-items-center m-auto w-full gap-4"
                    >
                        <Button
                            onClick={() => navigate('/')}
                        >
                            {language === 'en' ? 'Lazem GO to Home' : 'الذهاب إلى الصفحة الرئيسية'}
                        </Button>
                    </div>
                
                </CardFooter>
            </Card>
        </div>
    );};

export default NotAuthorized;