import WhatIsNew from "@/components/what-is-new";


const WhatIsNewPage = () => {
    return (
        <div>
            <WhatIsNew />
        </div>
    );
    }

export default WhatIsNewPage;