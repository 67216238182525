import { useErrorStore } from "@/components/context/ErrorContext";
import { users } from "./user-service";
import { removeAuthorizationToken } from "./api-header-options";

const errorHandlingService = async (error: any, from:any, priority:any) => {
    console.log('Error from: ', from);
    let errorDetail = error?.response?.data?.detail || "";
    let errorMessage = error?.message || "";
    let errorCode = error?.response?.status || "";
    console.log('Error code: ', error.response?.status);

    if (error?.response?.data?.code === 'token_not_valid') {
        console.log('Token not valid', error);
        // await removeAuthorizationToken();
        // await users.refreshToken();
        throw error;
    }
    else {
        const { setError } = useErrorStore.getState();
        setError(errorMessage + ". " + errorDetail, errorCode, priority);
        throw error;
    }
};

export { errorHandlingService };