import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import { useRef, useState } from "react";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import { useTheme } from "@/components/theme-provider";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { EmailTemplates } from "@/services/notification-services";
import { Textarea } from "@/components/ui/textarea";
import { useQuery } from "@tanstack/react-query";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import { LoaderIcon } from "lucide-react";
import { useAuth } from "@/components/context/AuthContext";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

const EmailManage = () => {
  const { language } = useLanguage();
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const emailEditorRef = useRef<EditorRef | null>(null);
  const [preview, setPreview] = useState(false);
  const { theme } = useTheme();
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [formError, setFormError] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const { checkPermission } = useAuth();
  const canEditEmail = checkPermission('change_emailtemplates');

  const {
    data: emailTemplate,
    isLoading: emailTemplateLoading,
  } = useQuery({
    queryKey: ["emailTemplate", id],
    queryFn: () => EmailTemplates.get(id).then((response) => {
      setSubject(response.subject);
      setTitle(response.title);
      setDescription(response.description);
      return response;
    }),
    refetchOnWindowFocus: false,
  });

  const {
    data: emailTemplatesVars,
    isLoading: emailTemplatesVarsLoading
  } = useQuery({
    queryKey: ["emailTemplatesVars"],
    queryFn: EmailTemplates.list_email_vars,
    refetchOnWindowFocus: false,
  });

  const exportHtml = () => {
    if (!checkInputs()) {
      return;
    }
    setLoading(true);
    setFormError("");
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { html, design } = data;
      let template_data = {
        title: title,
        subject: subject,
        body: html,
        body_json: design,
        description: description,
      }
      EmailTemplates.update(id, template_data).then((response) => {
        setLoading(false);
        navigate("/system-notification/emails");
      }).catch((error) => {
        setLoading(false);
        console.log("error", error);
        setFormError(language === "en" ? "Failed to save email template" : "فشل في حفظ قالب البريد الإلكتروني");
      }
      );

    });
  };

  const checkInputs = () => {
    if (!subject) {
      setFormError(language === "en" ? "Please enter the email subject" : "الرجاء إدخال موضوع البريد الإلكتروني");
      return false;
    }
    else if (!title) {
      setFormError(language === "en" ? "Please enter the email template title" : "الرجاء إدخال عنوان قالب البريد الإلكتروني");
      return false;
    }
    return true;
  };

  const duplicateTemplate = async () => {
    setLoading(true);
    let template = await EmailTemplates.get(id);
    let template_data = {
      title: template?.title + " - Copy",
      subject: template?.subject,
      body: template?.body,
      body_json: template?.body_json,
      description: template?.description,
    }
    EmailTemplates.create(template_data).then((response) => {
      navigate("/system-notification/emails/id/" + response.id);
      window.location.reload();
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.log("error", error);
      setFormError(language === "en" ? "Failed to duplicate email template" : "فشل في تكرار قالب البريد الإلكتروني");
    }
    );
  }

  const handleDelete = async () => {
    setLoading(true);
    await EmailTemplates.delete(id).then((response) => {
      navigate("/system-notification/emails");
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.log("error", error);
      setFormError(language === "en" ? "Failed to delete email template" : "فشل في حذف قالب البريد الإلكتروني");
    }
    );
  }

  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (preview) {
      unlayer?.hidePreview();
      setPreview(false);
    } else {
      unlayer?.showPreview({ device: "desktop" });
      setPreview(true);
    }
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer) => {
    if (emailTemplate && emailTemplate.body) {
      setLoading(true);
      unlayer.loadDesign(emailTemplate.body_json);
      setLoading(false);
    }
    if (!canEditEmail) {
      unlayer.showPreview({ device: "desktop" });
      setPreview(true);
    };
  }

  return (
    <div
      dir={language === "en" ? "ltr" : "rtl"}
    >
      {(emailTemplateLoading) && <LoadingScreen />}

      {
        loading &&
        <div
          className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 z-50 flex justify-center items-center"
        >
          <LoaderIcon />
        </div>
      }
      <div>
        <Card>
          <CardHeader>
            <div>
              <Button
                onClick={() => navigate('/system-notification/emails')}
                className="w-full my-4"
                variant={"secondary"}
              >
                {language === 'en' ? 'Back' : 'عودة'}
              </Button>

            </div>
            <CardTitle>
              {language === "en"
                ? "Email Builder"
                : "برنامج بناء البريد الإلكتروني"}
            </CardTitle>
            <div
              className="flex justify-between"
            >
              <Dialog>
                <DialogTrigger>
                  <Button
                    className="mx-6"
                    disabled={loading}
                    permission="change_emailtemplates"
                  >
                    {language === "en" ? "Duplicate Template" : "تكرار القالب"}
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>
                      {language === "en" ? "Duplicate Template" : "تكرار القالب"}
                    </DialogTitle>
                  </DialogHeader>
                  <DialogDescription>
                    {language === "en"
                      ? "This will duplicate the email template"
                      : "سيتم تكرار قالب البريد الإلكتروني"}
                  </DialogDescription>
                  <DialogFooter>
                    <Button
                      onClick={duplicateTemplate}
                      className="mx-auto w-full"
                      disabled={loading}
                      permission="change_emailtemplates"
                    >
                      {language === "en" ? "Duplicate" : "تكرار"}
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
              <Dialog>
                <DialogTrigger>
                  <Button
                    className="mx-6"
                    disabled={loading}
                    permission="delete_emailtemplates"
                    variant={"destructive"}
                  >
                    {language === "en" ? "Delete Template" : "حذف القالب"}
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>
                      {language === "en" ? "Delete Template" : "حذف القالب"}
                    </DialogTitle>
                  </DialogHeader>
                  <DialogDescription
                    className="text-center mt-4 text-xl"
                  >
                    {language === "en"
                      ? "This will delete the email template. Any notifications using this template will be deleted as well."
                      : "سيتم حذف قالب البريد الإلكتروني. سيتم حذف أي إشعارات تستخدم هذا القالب أيضًا."}
                  </DialogDescription>
                  <DialogFooter>
                    <Button
                      onClick={handleDelete}
                      className="mx-auto w-full"
                      disabled={loading}
                      permission="delete_emailtemplates"
                      variant={"destructive"}
                    >
                      {language === "en" ? "Delete" : "حذف"}
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
          </CardHeader>
          <CardContent>
            <CardDescription>
              {formError &&
                <p className="text-red-500 text-sm">{formError}</p>
              }
            </CardDescription>
          </CardContent>
          <CardFooter className="flex flex-col justify-center space-x-4">
            <div
            >
              {preview && (
                <Button
                  onClick={exportHtml}
                  className="mx-6"
                  disabled={loading}
                  permission="change_emailtemplates"
                >
                  {loading ? <LoadingIcon /> : language === "en" ? "Save" : "حفظ"}

                </Button>
              )}

              <Button
                onClick={togglePreview}
                variant={preview ? "destructive" : "default"}
                className="mx-6"
                disabled={loading}
                permission="change_emailtemplates"
              >
                {preview
                  ? language === "en"
                    ? "Build view"
                    : "عرض البناء"
                  : language === "en"
                    ? "Preview"
                    : "معاينة"}
              </Button>
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email subject" : "موضوع البريد الإلكتروني"}
              </Label>
              <Input
                className="w-full my-2 text-start"
                defaultValue={emailTemplate?.subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                disabled={!canEditEmail}
              />
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email template title" : "اسم القالب"}
              </Label>
              <Input
                className="w-full my-2 text-start"
                defaultValue={emailTemplate?.title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                disabled={!canEditEmail}
              />
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email template description" : "شرح القالب"}
              </Label>
              <Textarea
                className="w-full my-2 text-start"
                defaultValue={emailTemplate?.description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                disabled={!canEditEmail}
              />
            </div>
            {
              !emailTemplatesVarsLoading &&
              <div
                className="w-full"
              >
                <div

                >
                  <Select
                    onValueChange={(value: any) => {
                      setSelectedTemplate(value)
                    }}
                    dir={language === "en" ? "ltr" : "rtl"}
                    disabled={!canEditEmail}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={language === "en" ? "Select variable" : "اختر المتغير"} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {emailTemplatesVars.map((item: any, index: any) => (
                          <SelectItem
                            key={index}
                            value={item.vars}
                          >
                            {item.description}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {
                    selectedTemplate &&
                    <>
                      <div
                        className="text-start w-full mt-4"
                      >
                        {language === "en" ? "If you want to use a variable, put it inside 2 curly brackets like this: {{variable_name}}" : "إذا كنت ترغب في استخدام متغير ، ضعه داخل 2 قوس متعرج مثل هذا: {{اسم المتغير}}"}
                      </div>
                      <div
                        className="text-start w-full flex flex-wrap "
                      >
                        {
                          selectedTemplate?.map((item: any, index: any) => (
                            <div
                              key={"vvars" + index}
                              className="flex flex-col my-4 w-full lg:w-2/5"
                            >

                              <div
                                className="my-2"
                              >
                                {language === "en" ? "Var to use" : "اسم المتغير"}: {item.name}
                              </div>
                              <div>
                                {language === "en" ? "Description" : "الوصف"}: {item.description}
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </>
                  }
                </div>
              </div>
            }
          </CardFooter>

          {
            emailTemplate &&
            <EmailEditor
              ref={emailEditorRef}
              onReady={onReady}
              onLoad={() => {
                const unlayer = emailEditorRef.current?.editor;
                unlayer?.loadDesign(emailTemplate.body);
              }}
              options={{
                version: "latest",
                appearance: {
                  theme: theme === "dark" ? "dark" : "light",
                },
              }}
              tools={{ form: { enabled: false } }}
            />
          }
        </Card>

      </div>
    </div>
  );
};

export default EmailManage;
