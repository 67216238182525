import { EmailLogs } from "@/services/log-services";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "@/components/loading/loading-screen";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import parse from 'html-react-parser';
import { format, toZonedTime } from 'date-fns-tz';
import { RetryEmail } from "@/services/notification-services";

const EmailLogView = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const { id } = useParams();


    const {
        data: emailLogsData,
        isLoading: emailLogsLoading,
    } = useQuery({
        queryKey: ["emailLogs", id],
        queryFn: () => EmailLogs.get(id),
        refetchOnWindowFocus: false,
    });

    const convertDate = (date: any) => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Riyadh';
        let formattedDateTime = format(toZonedTime(date, timeZone), 'MMMM d, yyyy hh:mm a', { timeZone });
        return formattedDateTime;
    }

    const renderMessage = (message: string) => {
        const html = parse(message);
        return html;
    };

    return (
        <div>
            {emailLogsLoading && <LoadingScreen />}

            {emailLogsData &&
                <div>
                    <div className="my-4">
                        <Button
                            onClick={() => navigate("/email-logs")}
                            className="my-2"
                            variant={"secondary"}
                        >
                            {language === "en" ? "Back" : "عودة"}
                        </Button>
                        <h1 className="text-2xl font-semibold text-center">
                            {language === "en" ? "Email Log" : "سجل البريد الإلكتروني"}
                        </h1>
                        {
                            (emailLogsData?.status == "failed" && emailLogsData?.error_message) ?

                                <Button
                                    onClick={() => RetryEmail(emailLogsData?.id)}
                                    className="my-2"
                                    variant={"destructive"}
                                >
                                    {language === "en" ? "Retry Sending Email" : "أعادة ارسال البريد الإلكتروني"}
                                </Button> :
                                null
                        }
                    </div>
                    <Card className="mt-4">
                        <div className="flex flex-wrap justify-center">
                            <div
                                className="my-4 w-11/12 lg:w-2/5"
                            >
                                <h2 className="text-lg font-semibold">
                                    {language === "en" ? "Id" : "الرقم التعريفي"}
                                </h2>
                                <p>{emailLogsData?.id}</p>
                            </div>
                            <div
                                className="my-4 w-11/12 lg:w-2/5"
                            >
                                <h2 className="text-lg font-semibold">
                                    {language === "en" ? "Sender" : "المرسل"}
                                </h2>
                                <p>{emailLogsData?.sender}</p>
                            </div>
                            <div
                                className="my-4 w-11/12 lg:w-2/5"
                            >
                                <h2 className="text-lg font-semibold">
                                    {language === "en" ? "Sent To" : "تم الإرسال إلى"}
                                </h2>
                                <p>
                                    {emailLogsData?.sent_to?.map((email: any) => (
                                        <span className="flex m-auto w-max text-center" key={email}>{email}</span>
                                    ))}
                                </p>
                            </div>
                            <div
                                className="my-4 w-11/12 lg:w-2/5"
                            >
                                <h2 className="text-lg font-semibold">
                                    {language === "en" ? "Sent From" : "تم الإرسال من"}
                                </h2>
                                <p>{emailLogsData?.sent_from}</p>
                            </div>
                            <div
                                className="my-4 w-11/12 lg:w-2/5"
                            >
                                <h2 className="text-lg font-semibold">
                                    {language === "en" ? "Sent At" : "تم الإرسال في"}
                                </h2>
                                <p>{convertDate(emailLogsData?.created_at)}</p>
                            </div>
                            <div
                                className="my-4 w-11/12 lg:w-2/5"
                            >
                                <h2 className="text-lg font-semibold">
                                    {language === "en" ? "Status" : "الحالة"}
                                </h2>
                                <p>{emailLogsData?.status}</p>
                            </div>
                            <div
                                className="my-4 w-11/12"
                            >
                                <h2 className="text-lg font-semibold">
                                    {language === "en" ? "Trigger" : "المسبب"}
                                </h2>
                                <p>{emailLogsData?.trigger}</p>
                            </div>
                            <div
                                className="my-4 w-11/12"
                            >
                                <h2 className="text-lg font-semibold">
                                    {language === "en" ? "Subject" : "الموضوع"}
                                </h2>
                                <p>{emailLogsData?.subject}</p>
                            </div>
                            {
                                emailLogsData?.error_message ?
                                    <div
                                        className="my-4 w-11/12"
                                    >
                                        <h2 className="text-lg font-semibold">
                                            {language === "en" ? "Error Message" : "رسالة الخطأ"}
                                        </h2>
                                        <p>{emailLogsData?.error_message}</p>
                                    </div>
                                    : null
                            }

                            <div
                                className="my-4 w-11/12"
                            >
                                <h2 className="text-lg font-semibold">
                                    {language === "en" ? "Body" : "المحتوى"}
                                </h2>
                                {renderMessage(emailLogsData?.message)}
                            </div>
                        </div>
                    </Card>
                </div>
            }
        </div>
    );
}

export default EmailLogView;
