import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { projectTypes, stages, project_statuses } from "@/services/project-service";
import LoadingScreen from "@/components/loading/loading-screen";
import { IoMdAdd } from "react-icons/io";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/components/context/AuthContext";
import { useEffect } from "react";

const ProjectSettings = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const { checkPermission } = useAuth();
    const canViewProjectTypes = checkPermission('change_projecttypes');
    const canViewStages = checkPermission('change_stages');

    useEffect(() => {
        document.title = 'Lazem GO - Project Settings';
    } , []);

    // Fetch project types only if the user has permission
    const { data: projectType, isLoading: projectTypeLoading } = useQuery({
        queryKey: ['projectType'],
        queryFn: projectTypes.list,
        enabled: canViewProjectTypes,
        refetchOnWindowFocus: false
    });

    // Fetch stages only if the user has permission
    const { data: stagesData, isLoading: stagesLoading } = useQuery({
        queryKey: ['stages'],
        queryFn: stages.list,
        enabled: canViewStages,
        refetchOnWindowFocus: false,
    });

    const { data: projectStatusData, isLoading: projectStatusLoading } = useQuery({
        queryKey: ['projectStatus Data'],
        queryFn: project_statuses.list,
        refetchOnWindowFocus: false
    });

    // Loading state when queries are loading
    const isLoading =  projectTypeLoading || stagesLoading;

    return (
        <div dir={language === 'en' ? 'ltr' : 'rtl'}>
            {isLoading && <LoadingScreen />}

            <Button className="m-3 flex" onClick={() => navigate('/projects/')}>
                {language === 'en' ? 'Back to Projects' : 'العودة للمشاريع'}
            </Button>

            {/* Stages Section */}
            {canViewStages && (
                <Card className="rounded-none">
                    <CardHeader>
                        <CardTitle>{language === 'en' ? 'Stages' : 'المراحل'}</CardTitle>
                    </CardHeader>
                    <CardContent className="flex flex-wrap justify-content-center gap-4">
                        {stagesData && stagesData?.data?.map((stage: any) => (
                            <Card 
                                key={stage.id}
                                className="m-3 w-48 h-48 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={() => navigate(`/projects/settings/stage/id/${stage.id}`)}
                            >
                                <CardTitle className="text-2xl font-bold flex justify-content-center m-auto">
                                    {stage.index}
                                </CardTitle>
                                <CardHeader className="h-1/3 w-full flex justify-content-center m-auto">
                                    <CardTitle>{stage.title}</CardTitle>
                                </CardHeader>
                            </Card>
                        ))}
                        <Button
                            className="m-3 w-48 h-48 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                            onClick={() => navigate('/projects/create-stage')}
                            variant={"secondary"}
                            permission="add_stages"
                        >
                            <CardHeader className="w-full flex justify-content-center m-auto">
                                <CardTitle>
                                    <IoMdAdd size={50} className="text-center m-auto" />
                                </CardTitle>
                            </CardHeader>
                        </Button>
                    </CardContent>
                </Card>
            )}

            {/* Project Type Section */}
            {canViewProjectTypes && (
                <Card className="rounded-none">
                    <CardHeader>
                        <CardTitle>{language === 'en' ? 'Project Type' : 'نوع المشروع'}</CardTitle>
                    </CardHeader>
                    <CardContent className="flex flex-wrap justify-content-center gap-4">
                        {projectType && projectType?.data?.map((type: any, index: number) => (
                            <Card 
                                key={index}
                                className="m-3 w-48 h-48 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={() => navigate(`/projects/settings/project-type/id/${type.id}`)}
                            >
                                <CardHeader className="h-1/3 w-full flex justify-content-center m-auto">
                                    <CardTitle>{type.title}</CardTitle>
                                </CardHeader>
                            </Card>
                        ))}
                        <Button 
                            className="m-3 w-48 h-48 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                            onClick={() => navigate('/projects/create-project-type')}
                            variant={"secondary"}
                            permission="add_projecttypes"
                        >
                            <CardHeader className="w-full flex justify-content-center m-auto">
                                <CardTitle>
                                    <IoMdAdd size={50} className="text-center m-auto" />
                                </CardTitle>
                            </CardHeader>
                        </Button>
                    </CardContent>
                </Card>
            )}
                
                {/* Project Status Section */}
                <Card className="rounded-none">
                    <CardHeader>
                        <CardTitle>{language === 'en' ? 'Project Status' : 'حالة المشروع'}</CardTitle>
                    </CardHeader>
                    <CardContent className="flex flex-wrap justify-content-center gap-4">
                        {projectStatusData && projectStatusData?.data?.map((status: any) => (
                            <Card 
                                key={status.id}
                                className="m-3 w-48 h-48 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={() => navigate(`/projects/settings/status/id/${status.id}`)}
                            >
                                <CardHeader className="h-1/3 w-full flex justify-content-center m-auto">
                                    <CardTitle>{status.title}</CardTitle>
                                </CardHeader>
                            </Card>
                        ))}
                        <Button 
                            className="m-3 w-48 h-48 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                            onClick={() => navigate('/projects/create-project-status')}
                            variant={"secondary"}
                            permission="add_projectstatuses"
                        >
                            <CardHeader className="w-full flex justify-content-center m-auto">
                                <CardTitle>
                                    <IoMdAdd size={50} className="text-center m-auto" />
                                </CardTitle>
                            </CardHeader>
                        </Button>
                    </CardContent>
                </Card>

        </div>
    );
};

export default ProjectSettings;
