import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import LoadingScreen from "@/components/loading/loading-screen";
import { IoMdAdd } from "react-icons/io";
import { Button } from "@/components/ui/button";
import { departments } from "@/services/project-service";

const DepartmentDashboard = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();

  // Fetch departments only if the user has permission
  const { data: departmentsData, isLoading: departmentsLoading } = useQuery({
    queryKey: ["departments"],
    queryFn: departments.list,
    refetchOnWindowFocus: false,
  });

  return (
    <div
      dir={language === "en" ? "ltr" : "rtl"}
    >
      {departmentsLoading && <LoadingScreen />}
      {/* Departments Section */}
      <Card className="rounded-none">
        <CardHeader>
          <CardTitle>
            {language === "en" ? "Departments" : "الأقسام"}
          </CardTitle>
          <Button
            className="m-3 flex justify-content-start items-center w-fit"
            onClick={() => navigate("/organization")}
            variant={"secondary"}
          >
            {language === "en" ? "Back" : "العودة"}
          </Button>
        </CardHeader>
        <CardContent className="flex flex-wrap justify-content-center gap-4">
          {departmentsData &&
            departmentsData?.data?.map((department: any, index: number) => (
              <Card
                key={index}
                className="m-3 w-48 h-48 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                onClick={() =>
                  navigate(`/organization/department/id/${department.id}`)
                }
              >
                <CardHeader className="h-1/3 w-full flex justify-content-center m-auto">
                  <CardTitle>{department.title}</CardTitle>
                </CardHeader>
              </Card>
            ))}
          <Button
            className="m-3 w-48 h-48 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => navigate("/organization/create-department")}
            permission="add_departments"
            variant={"secondary"}
          >
            <CardHeader className="w-full flex justify-content-center m-auto">
              <CardTitle>
                <IoMdAdd size={50} className="text-center m-auto" />
              </CardTitle>
            </CardHeader>
          </Button>

        </CardContent>
      </Card>
    </div>
  );
};

export default DepartmentDashboard;
