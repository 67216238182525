import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { format, toZonedTime } from "date-fns-tz";
import { useQuery } from "@tanstack/react-query";
import { tickets } from "@/services/tickets-services";
import { Badge } from "@/components/ui/badge"


const MyRequestDashboard = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();


  const {
    data: ticketsData,
    isLoading: ticketsIsLoading,
    isFetching: ticketsIsFetching,
    refetch: refetchTickets,
  } = useQuery({
    queryKey: ["My tickets dashboard"],
    queryFn: () => {
      // const queryString = new URLSearchParams(search).toString();
      // if (queryString) {
      //   return tickets.list(queryString);
      // }
      return tickets.list_me();
    },
    refetchOnWindowFocus: false,
  });


  function formatDate(isoDate: any) {
    const timeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Riyadh";
    let formattedDateTime = format(
      toZonedTime(isoDate, timeZone),
      "MMMM d, yyyy hh:mm a",
      { timeZone }
    );
    return formattedDateTime;
  }

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      <div>
        <h1 className="text-3xl font-bold text-center m-auto mt-10">
          {language === "en" ? "My Requests" : "طلباتي"}
        </h1>

        <div className="flex justify-between items-end mx-6 my-4 gap-4">
          <Button onClick={() => navigate("/")} variant={"secondary"}>
            {language === "en" ? "Home Page" : "الصفحة الرئيسية"}
          </Button>
          <Button
            onClick={() => navigate("/open-ticket")}
            className="mx-6"
          >
            {language === "en" ? "Create Request" : "إنشاء طلب"}
          </Button>
        </div>
      </div>
      <div
        className=""
        >
        <h2 className="text-2xl font-bold text-start my-4 mx-6">
          {language === "en" ? "In Progress Requests" : "الطلبات قيد التنفيذ"}
        </h2>
        <div className="flex flex-wrap gap-4 mx-6">
          {ticketsData?.data?.map(
            (ticket: any, index: any) =>
              ticket.status !== "Closed" && (
                <Card
                  key={index}
                  className="w-64 cursor-pointer"
                  onClick={() => navigate(`/my-requests/id/${ticket.id}`)}
                >
                  <CardHeader>
                    <CardTitle
                      className="line-clamp-2"
                    >
                      {ticket.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="text-start">
                    <Badge
                      variant={ticket?.status === "In Progress" ? "destructive" : "outline"}
                    >
                      {ticket.status}
                    </Badge>
                    <CardDescription className="my-2">
                      {language === "en" ? "Created At" : "تاريخ الإنشاء"}
                      <br />
                      {formatDate(ticket.created_at)}
                    </CardDescription>
                    <CardDescription>
                      {language === "en" ? "Last Updated" : "آخر تحديث"}
                      <br />
                      {formatDate(ticket.updated_at)}
                    </CardDescription>
                  </CardContent>
                </Card>
              )
          )}
        </div>
        <h2 className="text-2xl font-bold text-start my-4 mx-6">
          {language === "en" ? "Closed Requests" : "الطلبات المغلقة"}
        </h2>
        <div className="flex flex-wrap gap-4 mx-6">
          {ticketsData?.data?.map(
            (ticket: any, index: any) =>
              ticket.status === "Closed" && (
                <Card
                  key={index}
                  className="w-64 cursor-pointer"
                  onClick={() => navigate(`/my-requests/id/${ticket.id}`)}
                >
                  <CardHeader>
                    <CardTitle
                      className="line-clamp-2"
                    >
                      {ticket.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="text-start">
                    <Badge
                      variant={"default"}
                    >
                      {ticket.status}
                    </Badge>
                    <CardDescription className="my-2">
                      {language === "en" ? "Created At" : "تاريخ الإنشاء"}
                      <br />
                      {formatDate(ticket.created_at)}
                    </CardDescription>
                    <CardDescription>
                      {language === "en" ? "Last Updated" : "آخر تحديث"}
                      <br />
                      {formatDate(ticket.updated_at)}
                    </CardDescription>
                  </CardContent>
                </Card>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default MyRequestDashboard;
