import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import LoadingScreen from "@/components/loading/loading-screen";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { formFiles, forms, formSubmissions } from "@/services/forms-service";
import { useQuery } from "@tanstack/react-query";
import { TiDeleteOutline } from "react-icons/ti";
import { IoCloudUploadOutline } from "react-icons/io5";
import { MultiSelect } from "@/components/ui/multi-select";
import Webcam from "react-webcam";
import LoadingIcon from "@/components/loading/loading-icon";
import {Camera} from "react-camera-pro";

const TaskForm = () => {
  const { id } = useParams();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<any>();
  const webcamRefs = useRef<any>({});
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);

  useEffect(() => {
    document.title = "Lazem GO - Task Form";
  } , []);

  const getForm = async () => {
    await forms
      .get(id)
      .then((response: any) => {
        setFormData(response.data);
        setPageLoading(false);
      })
      .catch((error: any) => {
        setPageLoading(false);
      });
  };

  const { isLoading } = useQuery({
    queryKey: ["form", id],
    queryFn: getForm,
    refetchOnWindowFocus: false,
  });


  
  
  const submitFile = async (files: any) => {
    let urls: any = [];
    for (let i = 0; i < files.length; i++) {
      const fileObject = files[i]?.file || files[i];

      let data = new FormData();
      data.append("file", fileObject, fileObject.name);

      await formFiles
        .create(data)
        .then((response: any) => {
          urls.push(response.data.id);
        })
        .catch((error: any) => {
          setFormError(error.message);
        });
    }
    return urls;
  };

  const capture = useCallback(
    (id: any) => {
      const webcamRef = webcamRefs.current[id];
      const imageSrc = webcamRef?.takePhoto();
      const currentDateTime = new Date();
      const imageFile = base64ToFile(
        imageSrc,
        `captured-image-${id}-${currentDateTime.getTime()}.jpeg`
      );

      const imageUrl = URL.createObjectURL(imageFile);

      let newQuestions = formData.questions.map((question: any) => {
        if (question.id === id) {
          question.files = [{ file: imageFile, url: imageUrl }];
        }
        return question;
      });
      setFormData({ ...formData, questions: newQuestions });
    },
    [formData]
  ); 

  const base64ToFile = (base64String: string, filename: string): File => {
    // Split base64 data to get the content and mime type
    const [header, base64Data] = base64String.split(",");
    const matchResult = header.match(/:(.*?);/);
    const mimeType = matchResult ? matchResult[1] : "";

    // Decode base64 to binary string (more memory efficient than atob for large strings)
    const byteString = window.atob(base64Data);

    // Create an ArrayBuffer with the same size as the binary string
    const buffer = new ArrayBuffer(byteString.length);

    // Create a view into the buffer
    const uintArray = new Uint8Array(buffer);

    // Fill the view with binary data
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    // Convert ArrayBuffer to Blob, then to File
    return new File([uintArray], filename, { type: mimeType });
  };

  const getMyLocation = (id: any) => {
    setIsFetchingLocation(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let newQuestions = formData.questions.map((question: any) => {
          if (question.id === id) {
            question.answer = `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`;
          }
          return question;
        });
        setFormData({ ...formData, questions: newQuestions });
        setIsFetchingLocation(false);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setFormError(
            language === "en"
              ? "Please change your browser settings to allow location access"
              : "يرجى تغيير إعدادات المتصفح للسماح بالوصول إلى الموقع"
          );
        }
        setIsFetchingLocation(false);
      },
      {
        enableHighAccuracy: true, // Use GPS if available for higher accuracy
        timeout: 5000, // Maximum time (in milliseconds) to wait for location
        maximumAge: 0, // No cache, force update every time
      }
    );
  };

  const handleSave = async () => {
    if (!checkRequiredFields()) {
      return;
    }
    setFormError("");
    setFormLoading(true);
    let answers = await Promise.all(
      formData.questions.map(async (question: any) => {
        let files = [];
        if (question.files) {
          files = await submitFile(question.files);
        }
        return {
          question_rel: question.id,
          answer:
            question.question_type === "checkbox" || question.question_type === "multiselect"
              ? question.answer
              : [question.answer],
          question_type: question.question_type,
          files: files,
        };
      })
    );
    let data = {
      form: id,
      answers: answers,
    };
    // Save task to API
    await formSubmissions
      .create(data)
      .then((response: any) => {
        setFormLoading(false);
        setIsSubmitted(true);
      })
      .catch((error: any) => {
        setFormError(error.message);
        setFormLoading(false);
      });
    setFormLoading(false);
  };

  const checkRequiredFields = () => {
    let result = true;
    formData.questions.forEach((question: any) => {
      if (question.is_required) {
        if (question.question_type === "file" || question.question_type === "camera") {
          if (!question.files || question.files.length === 0) {
            setFormError(
              language === "en"
                ? "Please fill all required fields"
                : "يرجى ملء جميع الحقول المطلوبة"
            );
            result = false;
            return result;
          }
        }
        else {
          if (!question.answer || question.answer === "") {
            setFormError(
              language === "en"
                ? "Please fill all required fields"
                : "يرجى ملء جميع الحقول المطلوبة"
            );
            result = false;
            return result;
          }
        }
      }
      else if (question.question_type === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (question.answer && !emailRegex.test(question.answer)) {
          setFormError(
            language === "en"
              ? "Please use a valid email address"
              : "يرجى استخدام عنوان بريد إلكتروني صالح"
          );
          result = false;
          return result;
        }
      }
    });
    return result;
  };

  const onFileChange = (e: any, index: any) => {
    const files = Array.from(e.target.files);
    const newQuestions = formData.questions.map((question: any) => {
      if (question.id === index) {
        return {
          ...question,
          files: question.files ? [...question.files, ...files] : files,
          answer: files.map((file: any) => file.name).join(", "),
        };
      }
      return question;
    });
    setFormData({ ...formData, questions: newQuestions });
  };
  
  const setFileField = (label: any, id: any, is_required: any, description:any) => {
    return (
      <div>
        <div className="relative my-6">
          <Label className="mb-1">
            {label}
            {is_required && <span className="text-red-500"> *</span>}
          </Label>
          <div>
            {description && (
              <Label className="text-slate-500">{description}</Label>
            )}
          </div>
          <div className="relative flex flex-col items-center gap-4 rounded border border-dashed border-slate-300 px-3 py-6 text-center text-sm font-medium transition-colors">
            <IoCloudUploadOutline
              className="inline-flex h-12 items-center justify-center self-center"
              size={24}
            />
            <input
              type="file"
              multiple
              onChange={(e) => onFileChange(e, id)}
              className="cursor-pointer opacity-0 absolute inset-0 w-full h-full"
            />
            <span className="text-slate-500">
              {language === "en" ? "Drag and drop or Browse" : "اسحب أو تصفح"}
            </span>
          </div>
        </div>
        {formData.questions
          .filter((question: any) => question.id === id)
          .map((question: any) =>
            question.files?.map((file: any) => (
              <div
                key={file.path}
                className="flex gap-6 items-center justify-between my-2"
              >
                <span>{file.name}</span>
                <TiDeleteOutline
                  onClick={() => {
                    const newQuestions = formData.questions.map(
                      (q: any) => {
                        if (q.id === id) {
                          return {
                            ...q,
                            files: q.files.filter(
                              (item: any) => item !== file
                            ),
                          };
                        }
                        return q;
                      }
                    );
                    setFormData({ ...formData, questions: newQuestions });
                  }}
                  className="text-red-500 cursor-pointer"
                  size={24}
                />
              </div>
            ))
          )}
      </div>
    );
  };
  

  const setInputField = (
    label: string,
    value: any,
    question_type: string,
    id: any,
    is_required: any,
    description: any
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">
          {label}
          {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <div>
          {description && (
            <Label className="text-slate-500">{description}</Label>
          )}
        </div>
        <Input
          type={question_type ? question_type : "text"}
          value={value}
          onChange={(e: any) => {
            let newOptions = formData.questions.map((question: any) => {
              if (question.id === id) {
                return { ...question, answer: e.target.value };
              }
              return question;
            });
            setFormData({ ...formData, questions: newOptions });
          }}
        />
      </div>
    );
  };

  const setWebcamField = (label: string, id: any, is_required: any, description:any) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      return (
        <div className="flex flex-col my-6">
          <Label className="mb-4">
            {label}
            {is_required && <span className="text-red-500"> *</span>}
          </Label>
          <div className="text-red-500 text-center">
            {language === "en"
              ? "Webcam not supported in this browser"
              : "كاميرا الويب غير مدعومة في هذا المتصفح"}
          </div>
        </div>
      );
    }
  
    const capturedImage = formData.questions.find(
      (question: any) => question.id === id && question?.files?.length > 0
    )?.files[0]?.url;
  
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-4">
          {label}
          {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <div>
          {description && (
            <Label className="text-slate-500">{description}</Label>
          )}
        </div>
        {capturedImage ? (
          <>
            <div className="flex flex-col items-center gap-4 rounded border border-dashed border-slate-300 px-3 py-6 text-center text-sm font-medium transition-colors">
              <img
                src={capturedImage}
                alt="captured"
                className="h-48 w-48 object-cover"
              />
            </div>
            <Button
              onClick={() => {
                const newQuestions = formData.questions.map((question: any) => {
                  if (question.id === id) {
                    return { ...question, files: [] };
                  }
                  return question;
                });
                setFormData({ ...formData, questions: newQuestions });
              }}
              className="mt-4"
              variant={"destructive"}
            >
              {language === "en" ? "Retake" : "إعادة التقاط"}
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                webcamRefs.current[id].switchCamera();
              }}
              className="mt-4"
              variant={"secondary"}
            >
              {language === "en" ? "Switch Camera" : "تبديل الكاميرا"}
            </Button>
            <Camera
              ref={(ref) => (webcamRefs.current[id] = ref)}
              aspectRatio={4 / 3}
              errorMessages={{
                noCameraAccessible: language === "en" ? "No camera available" : "لا تتوفر كاميرا",
                permissionDenied: language === "en" ? "Permission denied. Please refresh and give camera permission." : "تم رفض الإذن. يرجى تحديث الصفحة ومنح إذن الكاميرا.",
                switchCamera: language === "en" ? "It is not possible to switch camera to different one because there is only one video device accessible." : "لا يمكن تبديل الكاميرا لأخرى لأن هناك جهاز فيديو واحد فقط يمكن الوصول إليه.",
                canvas: language === "en" ? "Canvas is not supported." : "لا يتم دعم الرسم",
              }}
            />
            <Button
              onClick={() => {
                capture(id);
              }}
              className="mt-4"
              variant={"secondary"}
            >
              {language === "en" ? "Capture" : "التقاط"}
            </Button>
          </>
        )}
      </div>
    );
  };
  

  const setLocationField = (label: string, id: any, is_required: any, description:any) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-4">
          {label}
          {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <div>
          {description && (
            <Label className="text-slate-500">{description}</Label>
          )}
        </div>
        <div className="flex flex-col space-y-4">
          <Input
            type="text"
            disabled
            value={
              formData.questions.find((question: any) => question.id === id)
                ?.answer
            }
          />
        </div>
        <Button
          onClick={() => getMyLocation(id)}
          variant={"secondary"}
          disabled={isFetchingLocation}
        >
          {isFetchingLocation && 
            <LoadingIcon />
          }
          {language === "en" ? "Get Location" : "الحصول على الموقع"}
        </Button>
      </div>
    );
  };
  

  const setListField = (
    label: string,
    options: any,
    id: any,
    is_required: any,
    description: any
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-4">
          {label}
          {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <div>
          {description && (
            <Label className="text-slate-500">{description}</Label>
          )}
        </div>
        <Select
          onValueChange={(value: any) => {
            let newOptions = formData.questions.map((question: any) => {
              if (question.id === id) {
                question.answer = value;
              }
              return question;
            });
            setFormData({ ...formData, questions: newOptions });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder={label} />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option: any) => (
                <SelectItem 
                  key={option?.id} 
                  value={option}
                >
                  {option.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    );
  };

  const setSelectField = (
    label: string,
    options: any,
    id: any,
    is_required: any,
    description: any
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-4">
          {label}
          {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <div>
          {description && (
            <Label className="text-slate-500">{description}</Label>
          )}
        </div>
        <Select
          onValueChange={(value: any) => {
            const newQuestions = formData.questions.map((question: any) => {
              if (question.id === id) {
                return { ...question, answer: value };
              }
              return question;
            });
            setFormData({ ...formData, questions: newQuestions });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder={label} />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option: any) => (
                <SelectItem key={option + "_" + id} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    );
  };
  

  const setMultiSelectField = (
    label: string,
    options: any,
    id: any,
    is_required: any,
    description: any
  ) => {
    const _options = options.map((option: any) => ({
      value: option,
      label: option,
    }));
  
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-4">
          {label}
          {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <div>
          {description && (
            <Label className="text-slate-500">{description}</Label>
          )}
        </div>
        <MultiSelect
          onValueChange={(value: any) => {
            const newQuestions = formData.questions.map((question: any) => {
              if (question.id === id) {
                return { ...question, answer: value };
              }
              return question;
            });
            setFormData({ ...formData, questions: newQuestions });
          }}
          defaultValue={[]}
          options={_options}
        >
          {_options.map((option: any) => (
            <SelectItem key={option.value + "_" + id} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </MultiSelect>
      </div>
    );
  };

  const setRadioField = (
    label: string,
    options: any,
    id: any,
    is_required: any,
    description: any
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-4">
          {label}
          {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <div>
          {description && (
            <Label className="text-slate-500">{description}</Label>
          )}
        </div>
        <RadioGroup
          className="flex flex-col space-y-2"
          onValueChange={(value: any) => {
            const newQuestions = formData.questions.map((question: any) => {
              if (question.id === id) {
                return { ...question, answer: value };
              }
              return question;
            });
            setFormData({ ...formData, questions: newQuestions });
          }}
        >
          {options?.map((option: any) => (
            <div
              key={option.id}
              className={`flex space-x-2 ${
                language === "en" ? "flex-row" : "flex-row-reverse"
              }`}
            >
              <RadioGroupItem
                value={option}
                id={option + "_" + id}
                className="mx-2"
              />
              <Label htmlFor={option + "_" + id}>{option}</Label>
            </div>
          ))}
        </RadioGroup>
      </div>
    );
  };
  

  const setCheckboxField = (
    label: string,
    options: any,
    id: any,
    is_required: any,
    description: any
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-4">
          {label}
          {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <div>
          {description && (
            <Label className="text-slate-500">{description}</Label>
          )}
        </div>
        {options.map((option: any) => (
          <div key={option + id}>
            <Checkbox
              id={option + id}
              className="mx-2 my-2"
              onCheckedChange={(checked: boolean) => {
                const newQuestions = formData.questions.map((question: any) => {
                  if (question.id === id) {
                    const answer = checked
                      ? [...(question.answer || []), option]
                      : question.answer?.filter((item: any) => item !== option);
                    return { ...question, answer };
                  }
                  return question;
                });
                setFormData({ ...formData, questions: newQuestions });
              }}
            />
            <Label htmlFor={option + id}>{option}</Label>
          </div>
        ))}
      </div>
    );
  };
  

  const setQuestionField = (question: any) => {
    switch (question.question_type) {
      case "radio":
        return setRadioField(
          question.question,
          question.choices,
          question.id,
          question.is_required,
          question.description
        );
      case "checkbox":
        return setCheckboxField(
          question.question,
          question.choices,
          question.id,
          question.is_required,
          question.description

        );
      case "select":
        return setSelectField(
          question.question,
          question.choices,
          question.id,
          question.is_required,
          question.description

        );
      case "file":
        return setFileField(
          question.question,
          question.id,
          question.is_required,
          question.description
        );
      case "multiselect":
        return setMultiSelectField(
          question.question,
          question.choices,
          question.id,
          question.is_required,
          question.description

        );
      case "camera":
        return setWebcamField(
          question.question,
          question.id,
          question.is_required,
          question.description

        );
      case "location":
        return setLocationField(
          question.question,
          question.id,
          question.is_required,
          question.description

        );
      case "list":
        return setListField(
          question.question,
          question.choices,
          question.id,
          question.is_required,
          question.description

        );
      default:
        return setInputField(
          question.question,
          question.answer,
          question.question_type,
          question.id,
          question.is_required,
          question.description

        );
    }
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {pageLoading &&  <LoadingScreen />}

      {formData && (
        <>
          {formData.is_open ? (
            <>
              {isSubmitted ? (
                <div className="h-screen flex items-center justify-center">
                  <Card className=" P-4 text-center text-green-500 font-semibold">
                    <CardHeader>
                      <CardTitle>
                        {language === "en"
                          ? "Successfull submission"
                          : "تم التقديم بنجاح"}
                      </CardTitle>
                      <CardFooter className="flex justify-center mt-6">
                        <Button onClick={() => navigate(-1)} className="mt-10">
                          {language === "en" ? "Back" : "عودة"}
                        </Button>
                      </CardFooter>
                    </CardHeader>
                  </Card>
                </div>
              ) : (
                <Card className="max-w-xl mx-auto mt-8">
                  <CardHeader>
                    <CardTitle>{formData.title}</CardTitle>
                  </CardHeader>
                  <CardContent className="text-start">
                    {formData?.questions?.map((question: any, index: number) => (
                      <div key={index}>{setQuestionField(question)}</div>
                    ))}
                  </CardContent>
                  <CardFooter className="flex flex-col space-y-4">
                    {formError !== "" && (
                      <div className="text-red-500 text-start w-full text-sm font-semibold">
                        {formError}
                      </div>
                    )}
                    <Button
                      onClick={handleSave}
                      disabled={formLoading}
                      className="w-full"
                    >
                      {language === "en" ? "Save" : "حفظ"}
                    </Button>
                  </CardFooter>
                </Card>
              )}
            </>
          ) : (
            <div className="h-screen flex items-center justify-center">
              <Card className=" P-4 text-center text-green-500 font-semibold">
                <CardHeader>
                  <CardTitle>
                    {language === "en"
                      ? "This form is submitted"
                      : "تم تقديم هذا النموذج"}
                  </CardTitle>
                  <CardFooter className="flex justify-center mt-6">
                    <Button onClick={() => navigate(-1)} className="mt-10">
                      {language === "en" ? "Back" : "عودة"}
                    </Button>
                  </CardFooter>
                </CardHeader>
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TaskForm;
