import { useLanguage } from "@/components/context/LanguageContext";
import { getMyProfile, updateProfile, users } from "@/services/user-service";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import LoadingScreen from "@/components/loading/loading-screen";
import { useQuery } from "@tanstack/react-query";
import { departments } from "@/services/project-service";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
} from "@/components/ui/select";
import { FiRefreshCw } from "react-icons/fi";
import { MultiSelect } from "@/components/ui/multi-select";
import { PermissionGroups } from "@/services/permission-group-services";

const EditUser = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState<any>();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [updatedFields, setUpdatedFields] = useState<any>();


  useEffect(() => {
    document.title = "Lazem GO - Edit User";
  }, []);

  const {
    isLoading: profileLoading,
    refetch: fetchProfile,
    isRefetching: isProfileFetching,
  } = useQuery({
    queryKey: ["profile"],
    queryFn: () => {
      return users
        .get(id)
        .then((response: any) => {
          setProfile(response.data);
        })
        .catch((error: any) => {
          console.log("Error", error);
        });
    },
    refetchOnWindowFocus: false,
  });

  const {
    data: departmentsData,
    isLoading: departmentsLoading,
    refetch: fetchDepartments,
    isRefetching: isDepartmentFetching,
  } = useQuery({
    queryKey: ["departments"],
    queryFn: () => {
      return departments.list().then((response: any) => {
        return response.data;
      });
    },
    refetchOnWindowFocus: false,
  });
  const { data: permission_groups, isLoading: isPermissionGroupsLoading } =
    useQuery({
      queryKey: ["permission_groups"],
      queryFn: async () => {
        return await PermissionGroups.list().then((response: any) => {
          return response;
        });
      },
      refetchOnWindowFocus: false,
    });

  const checkUpdatedFields = () => {
    if (!updatedFields || updatedFields === null) {
      setFormError(
        language === "en"
          ? "Please update at least one field"
          : "الرجاء تحديث حقل واحد على الأقل"
      );
      return false;
    } else if (
      updatedFields.first_name === "" ||
      updatedFields.last_name === ""
    ) {
      setFormError(
        language === "en"
          ? "First Name and Last Name are required"
          : "الإسم الأول و الأخير مطلوبين"
      );
      return false;
    } else if (
      updatedFields.email === "" ||
      (updatedFields.email && !updatedFields.email.endsWith("@lazem.sa"))
    ) {
      setFormError(
        language === "en"
          ? "Please enter a valid Lazem email"
          : "الرجاء إدخال بريد إلكتروني خاص بلازم"
      );
      return false;
    } else if (
      updatedFields.staff_profile &&
      updatedFields.staff_profile.department !== "" &&
      updatedFields.staff_profile.role === null
    ) {
      setFormError(
        language === "en"
          ? "Please select a role"
          : "الرجاء اختيار المسمى الوظيفي"
      );
      return false;
    }
    return true;
  };

  const updateProfileData = async () => {
    if (checkUpdatedFields()) {
      setFormLoading(true);
      setFormError("");
      await users
        .update(id, updatedFields)
        .then((response: any) => {
          fetchProfile();
          setUpdatedFields(null);
          setFormLoading(false);
          setIsEditing(false);
        })
        .catch((error: any) => {
          console.log("Error", error);
          setFormLoading(false);
        });
    }
  };
  const setMultiSelectField = (
    label: string,
    field: string,
    options: any,
    default_value: any,
    with_add?: boolean,
    url?: string
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <MultiSelect
          defaultValue={default_value}
          options={options}
          onValueChange={(value: any) => {
            setUpdatedFields({ ...updatedFields, [field]: value });
          }}
          animation={2}
          className="w-full"
          disabled={!isEditing}
        />
        {with_add && (
          <Button
            variant="link"
            className="text-sm flex justify-start w-fit"
            onClick={() => window.open(url ? url : "", "_blank")}
          >
            {language === "en" ? "Add New" : "إضافة جديد"}
          </Button>
        )}
      </div>
    );
  };

  const setInputField = (
    label: string,
    field: string,
    value: any,
    type?: string,
    is_disabled?: boolean
  ) => {
    return (
      <div className="flex flex-col my-4 text-start">
        <Label className="mb-2">{label}</Label>
        <Input
          type={type ? type : "text"}
          value={value}
          disabled={is_disabled ? is_disabled : !isEditing}
          onChange={(e) => {
            const newValue = e.target.value;
            if (field === "phone_number" || field === "address") {
              setProfile((prev: any) => ({
                ...prev,
                profile_user: {
                  ...prev?.profile_user,
                  [field]: newValue,
                },
              }));
              setUpdatedFields((prev: any) => ({
                ...prev,
                profile_user: {
                  ...prev?.profile_user,
                  [field]: newValue,
                },
              }));
            } else {
              setProfile((prev: any) => ({
                ...prev,
                [field]: newValue,
              }));
              setUpdatedFields((prev: any) => ({
                ...prev,
                [field]: newValue,
              }));
            }
          }}
        />
      </div>
    );
  };

  const setSelectField = (
    label: string,
    field: string,
    options: any,
    default_value: any,
    with_add?: boolean,
    url?: string
  ) => {
    if (!options) {
      return (
        <>
          <LoadingIcon />
        </>
      );
    } else {
      return (
        <div className="flex flex-col my-6">
          <Label className="mb-1">{label}</Label>
          <div className="flex gap-2">
            <div className={`${with_add ? "w-4/5 " : "w-full"}`}>
              <Select
                disabled={!isEditing}
                onValueChange={(value: any) => {
                  if (field === "department") {
                    setProfile((prev: any) => ({
                      ...prev,
                      staff_profile: {
                        ...prev?.staff_profile,
                        [field]: { id: value },
                        role: null,
                      },
                    }));
                    setUpdatedFields((prev: any) => ({
                      ...prev,
                      staff_profile: {
                        ...prev?.staff_profile,
                        [field]: value,
                        role: null,
                      },
                    }));
                  } else {
                    setProfile((prev: any) => ({
                      ...prev,
                      staff_profile: {
                        ...prev?.staff_profile,
                        [field]: value,
                      },
                    }));
                    setUpdatedFields((prev: any) => ({
                      ...prev,
                      staff_profile: {
                        ...prev?.staff_profile,
                        [field]: value,
                      },
                    }));
                  }
                }}
                defaultValue={default_value}
              >
                <SelectTrigger
                  isFetch={isDepartmentFetching || departmentsLoading}
                >
                  <SelectValue
                    placeholder={
                      language === "en" ? "Select" + label : "اختر" + label
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>
                      {language === "en" ? "Select" + label : "اختر" + label}
                    </SelectLabel>
                    {options?.map((option: any) => (
                      <SelectItem key={option.id} value={option.id}>
                        {option.title}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div>
              {with_add && (
                <Button
                  variant="outline"
                  onClick={() => functionToCall(field)}
                  className=""
                >
                  <FiRefreshCw />
                </Button>
              )}
            </div>
          </div>
          {with_add && (
            <Button
              variant="link"
              className="text-sm flex justify-start w-fit"
              onClick={() => window.open(url ? url : "", "_blank")}
            >
              {language === "en" ? "Add New" : "إضافة جديد"}
            </Button>
          )}
        </div>
      );
    }
  };

  const functionToCall = (field: string) => {
    if (field === "department") {
      fetchDepartments();
    }
  };
  const permissionGroupsSerializer = (data: any) => {
    if (!data) {
      return [];
    }
    return data.map((group: any) => {
      return {
        value: group.id,
        label: group.name,
      };
    });
  };
  const defaultValueSerializer = (data: any) => {
    if (!data) {
      return [];
    }
    return data.map((group: any) => {
      return group.id;
    });
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {profileLoading || (isProfileFetching && <LoadingScreen />)}
      {profile && departments && (
        <>
          <Card className="w-[400px] mx-auto mt-8">
            <CardHeader>
              <Button
                onClick={() => navigate("/organization/users")}
                className="w-full my-4"
                variant={"secondary"}
              >
                {language === "en" ? "Back" : "رجوع"}
              </Button>
              <CardTitle className="text-center font-semibold text-xl">
                {language === "en" ? "" : "ملف"} {profile.first_name}{" "}
                {profile.last_name} {language === "en" ? "Profile" : " الشخصي"}
              </CardTitle>

              <div className="flex justify-start">
                <Button
                  onClick={() => setIsEditing(!isEditing)}
                  className="w-fit mt-6"
                  variant={isEditing ? "destructive" : "default"}
                  permission="change_user"
                >
                  {isEditing
                    ? language === "en"
                      ? "Cancel"
                      : "إلغاء"
                    : language === "en"
                      ? "Edit"
                      : "تعديل"}
                </Button>
              </div>

            </CardHeader>
            <CardContent>
              <div className="text-start space-y-4 flex flex-col">
                {setInputField(
                  language === "en" ? "First Name" : "اللإسم الأول",
                  "first_name",
                  profile?.first_name
                )}
                {setInputField(
                  language === "en" ? "Middle Name" : "اللإسم الأوسط",
                  "middle_name",
                  profile?.middle_name
                )}
                {setInputField(
                  language === "en" ? "Last Name" : "الإسم الأخير",
                  "last_name",
                  profile?.last_name
                )}
                {setInputField(
                  language === "en" ? "Email" : "البريد الإلكتروني",
                  "email",
                  profile.email,
                  'email',
                  true
                )}
                {setInputField(
                  language === "en" ? "Phone" : "الهاتف",
                  "phone_number",
                  profile?.profile_user?.phone_number
                )}
                {setInputField(
                  language === "en" ? "Address" : "العنوان",
                  "address",
                  profile?.profile_user?.address
                )}
                {isDepartmentFetching || departmentsLoading ? (
                  <LoadingIcon />
                ) : (
                  <>
                    {setSelectField(
                      language === "en" ? "Department" : "القسم",
                      "department",
                      departmentsData,
                      profile?.staff_profile?.department?.id,
                      true,
                      "/organization/create-department"
                    )}

                    {profile?.staff_profile?.department?.id &&
                      departmentsData &&
                      setSelectField(
                        language === "en" ? "Role" : "المسمى الوظيفي",
                        "role",
                        departmentsData.find(
                          (department: any) =>
                            department.id ===
                            profile.staff_profile?.department.id
                        )?.roles,
                        profile?.staff_profile?.role?.id || "",
                        false
                      )}
                  </>
                )}
                {isPermissionGroupsLoading ? (
                  <LoadingIcon />
                ) : (
                  setMultiSelectField(
                    language === "en" ? "Permission Group" : "مصفوفة الصلاحيات",
                    "groups",
                    permissionGroupsSerializer(permission_groups) || [],
                    defaultValueSerializer(profile?.groups),
                    true,
                    "/permission-group/create"
                  )
                )}
                <CardFooter className="flex flex-col items-start gap-4">
                  {formError && (
                    <div className="text-red-500 font-semibold ">
                      {formError}
                    </div>
                  )}
                  {isEditing && (
                    <Button
                      onClick={() => updateProfileData()}
                      className="mt-4 w-full"
                      permission="change_user"
                    >
                      {formLoading ? (
                        <LoadingIcon />
                      ) : language === "en" ? (
                        "Update"
                      ) : (
                        "تحديث"
                      )}
                    </Button>
                  )}
                </CardFooter>
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

export default EditUser;
