import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { project_statuses } from "@/services/project-service";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";

const EditProjectStatus = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { language } = useLanguage();
    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState("");
    const [updatedFields, setUpdatedFields] = useState<any>();
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        document.title = "Lazem GO - Edit Status";
    }, []);

    const {
        data: statusData,
        isLoading: statusLoading,
        isFetching: statusFetching,
        refetch: refetchStatus,
    } = useQuery({
        queryKey: ["status edit"],
        queryFn: () => project_statuses.get(id),
        refetchOnWindowFocus: false,
    });

    const handleUpdate = async () => {
        if (checkForm()) {
            setFormError("");
            setFormLoading(true);
            await project_statuses.update(id, updatedFields)
                .then(() => {
                    refetchStatus();
                })
                .catch((error) => {
                    console.log("Error", error);
                })
                .finally(() => {
                    setFormLoading(false);
                    setIsEditing(false);
                });
        }
    };

    const checkForm = () => {
        if (statusData?.data?.title === "") {
            setFormError(language === "en" ? "Title is required" : "العنوان مطلوب");
            return false;
        }
        else if (statusData?.data?.title === updatedFields.title || statusData?.data?.description === updatedFields.description) {
            setFormError(language === "en" ? "No changes detected" : "لا توجد تغييرات");
            return false;
        }
        else if (updatedFields.title === "") {
            setFormError(language === "en" ? "Title is required" : "العنوان مطلوب");
            return false;
        }
        return true;
    };

    return (
        <div
            className="flex flex-col items-center justify-center h-full"
            dir={language === "en" ? "ltr" : "rtl"}
        >
            {(statusLoading || statusFetching) && <LoadingScreen />}

            {
                <Card className="w-full md:w-1/2">
                    <CardHeader>
                        <Button
                            onClick={() => navigate("/projects/settings")}
                            className="w-full my-4"
                            variant={"secondary"}
                        >
                            {language === "en" ? "Back" : "عودة"}
                        </Button>
                        <CardTitle>
                            {language === "en" ? "Edit Status" : "تعديل الحالة"}
                        </CardTitle>
                        <Button
                            onClick={() => setIsEditing(!isEditing)}
                            className="w-fit"
                            permission="change_status"
                            variant={isEditing ? "destructive" : "default"}
                        >
                            {isEditing ? (language === "en" ? "Cancel" : "إلغاء") : (language === "en" ? "Edit" : "تعديل")}
                        </Button>
                    </CardHeader>
                    <CardContent className="text-start">
                        <div className="">
                            <div className="mb-4">
                                <Label className="mb-4">
                                    {language === "en" ? "Title" : "العنوان"}
                                </Label>
                                <Input
                                    defaultValue={statusData?.data?.title}
                                    onChange={(e) => {
                                        setUpdatedFields({
                                            ...updatedFields,
                                            title: e.target.value,
                                        });
                                    }}
                                    disabled={!isEditing}
                                />
                            </div>
                            <div className="mb-4">
                                <Label className="mb-4">
                                    {language === "en" ? "Description" : "الوصف"}
                                </Label>
                                <Input
                                    defaultValue={statusData?.data?.description}
                                    onChange={(e) => {
                                        setUpdatedFields({
                                            ...updatedFields,
                                            description: e.target.value,
                                        });
                                    }}
                                    disabled={!isEditing}
                                />
                            </div>
                            {formError !== "" ? (
                                <div className="mb-4 text-red-500">{formError}</div>
                            ) : null}
                            <div className="flex justify-end">
                                <Button
                                    onClick={handleUpdate}
                                    disabled={formLoading || !isEditing || !updatedFields} 
                                    className="w-full"
                                    permission="change_status"
                                >
                                    {formLoading ? (
                                        <>
                                            <LoadingIcon />
                                        </>
                                    ) : language === "en" ? (
                                        "Update Status"
                                    ) : (
                                        "تحديث الحالة"
                                    )}
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            }
        </div>
    );
};

export default EditProjectStatus;
