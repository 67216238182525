import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { 
    Card,
    CardHeader,
 } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const TaskTypeForm = ({id}:any) => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    return (
        <Card>
            <CardHeader>
                <Button
                    onClick={() => navigate('/tasks/form/id/' + id)}
                    className="w-fit mx-auto"
                >
                    {language === 'en' ? 'Lazem GO to form' : 'الذهاب إلى النموذج'}
                </Button>
            </CardHeader>
        </Card>
    );
};

export default TaskTypeForm;