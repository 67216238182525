import { useLanguage } from "@/components/context/LanguageContext";
import LoadingScreen from "@/components/loading/loading-screen";
import { PermissionGroups } from "@/services/permission-group-services";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
  Card,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const PermissionGroupDashboard = () => {
  const {language} = useLanguage();
  const navigate = useNavigate();
  
  const { data:permissionGroupData, isLoading:permissionGroupLoading } = useQuery({
    queryKey: ["permissionGroupList"],
    queryFn: ()=>PermissionGroups.list().catch((error) => {
      console.error("Errorrrr",error)
    }),
    refetchOnWindowFocus: false
    });

    useEffect(() => {
      document.title = "Lazem GO - Permission Groups";
    } , []);

  return (
    <div
      dir={language === "en" ? "ltr" : "rtl"}
      className="w-full p-4"
    >
      {permissionGroupLoading && <LoadingScreen />}

        <h1
          className="text-2xl font-semibold mt-4"
        >
          {language === "en" ? "Permission Management" : "إدارة الصلاحيات"}        
        </h1>
        <div
          className="flex justify-start items-center gap-6 mt-4 mx-6 flex-wrap"
        >
          <Button
            className=""
            onClick={()=>navigate('/permission-group/create')}
            permission="add_group"
          >
            {language === "en" ? "Create Permission Group" : "إنشاء مجموعة صلاحيات"}
          </Button>
          <Button
            className=""
            onClick={()=>navigate('/')}
            variant={"secondary"}
          >
            {language === "en" ? "Home Page" : "الصفحة الرئيسية"}
          </Button>
        </div>

        <div
          className="mt-4 flex flex-wrap gap-4 justify-content-center items-center w-fit mx-auto p-4"
        >
          {permissionGroupData && permissionGroupData?.map((permissionGroup: any) => (
            <Card 
              key={permissionGroup.id}
              className="w-64 h-64 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
            >
              <CardHeader
                className="h-1/3 w-full flex justify-content-center m-auto"
                onClick={() => navigate(`/permission-group/id/${permissionGroup.id}`)}
              >
                <CardTitle
                  className="text-2xl font-bold flex justify-content-center m-auto"
                >
                  {permissionGroup.name}
                </CardTitle>
              </CardHeader>
            </Card>
          )
          )}
        </div>
    </div>
  );
};

export default PermissionGroupDashboard;