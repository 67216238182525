import ApiUrl from "./api-url";
import axios, {isCancel} from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";

const getVehicles = async () => {
    const url = `${ApiUrl}/v1/fleet/vehicles/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
        errorHandlingService(error, " list vehicles", "high");
        throw error;
    });
}

const getVehicle = async (id: number) => {
    const url = `${ApiUrl}/v1/fleet/vehicles/${id}/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
        errorHandlingService(error, " get vehicle", "high");
        throw error;
    });
}

const addVehicle = async (data: any) => {
    const url = `${ApiUrl}/v1/fleet/vehicles/`;
    return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
        errorHandlingService(error, " add vehicle", "medium");
    });
}

const updateVehicle = async (id: number, data: any) => {
    const url = `${ApiUrl}/v1/fleet/vehicles/${id}/`;
    return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
        errorHandlingService(error, " update vehicle", "medium");
    });
}

const createVehicleStatus = async (data: any) => {
    const url = `${ApiUrl}/v1/fleet/status/`;
    return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
        errorHandlingService(error, " create vehicle status", "medium");
    });
}

const getVehicleStatus = async () => {
    const url = `${ApiUrl}/v1/fleet/status/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
        errorHandlingService(error, " get vehicle status", "high");
        throw error;
    });
}

export {getVehicles, getVehicle, addVehicle, updateVehicle, createVehicleStatus, getVehicleStatus};