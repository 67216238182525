import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  PermissionGroups,
  Permissions,
} from "@/services/permission-group-services";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { title } from "process";
const PermissionGroupEdit = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isScuccess, setIsSuccess] = useState(false);
  const queryClient = useQueryClient();


  useEffect(() => {
    document.title = "Lazem GO - Edit Permission Group";
  }, []);

  const { data: permissionData, isLoading: permissionLoading } = useQuery({
    queryKey: ["editPermissionGroupList"],
    queryFn: () =>
      Permissions.list()
        .then(async (res: any) => {
          return await sortPermissionsByEndpoint(res);
        })
        .catch((error) => { }),
    refetchOnWindowFocus: false,
  });

  const {
    data: permissionGroupDataById,
    isLoading: permissionGroupByIdLoading,
    isRefetching: permissionGroupByIdRefetching,
    refetch: permissionGroupByIdRefetch,
  } = useQuery({
    queryKey: ["permissionGroupListById"],
    queryFn: () =>
      PermissionGroups.get(id).catch((error) => { }),
    refetchOnWindowFocus: false,
  });

  const handelSubmit = async () => {
    setIsSubmit(true);
    await PermissionGroups.update(id, permissionGroupDataById)
      .then(() => {
        setIsSubmit(false);
        setIsEdit(false);
        setIsSuccess(true);
        window.scrollTo(0, 0);
        permissionGroupByIdRefetch();
        setTimeout(() => {
          setIsSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmit(false);
      });
  };

  const sortPermissionsByEndpoint = (data: any) => {
    const endpoints: any = {};

    data.forEach((permission: any) => {
      const [action, endpoint] = permission.codename.split("_");
      const permission_names = permission.name.split(" ");
      let permission_name = "";
      for (let i = 2; i < permission_names.length; i++) {
        permission_name += permission_names[i] + " ";
      }
      if (!endpoints[endpoint]) {
        endpoints[endpoint] = {
          view: null,
          add: null,
          change: null,
          delete: null,
          title: permission_name
        };
      }

      if (action === "view") {
        endpoints[endpoint].view = permission;
      } else if (action === "add") {
        endpoints[endpoint].add = permission;
      } else if (action === "change") {
        endpoints[endpoint].change = permission;
      } else if (action === "delete") {
        endpoints[endpoint].delete = permission;
      }
    });

    return endpoints;
  };

  const handlePermissionDelete = async () => {
    setIsSubmit(true);
    await PermissionGroups.delete(id)
      .then(() => {
        setIsSubmit(false);
        navigate("/permission-group");
      })
      .catch((error) => {
        console.log(error);
        setIsSubmit(false);
      });
  }

  const handlePermissionChange = (isChecked: any, permission: any) => {
    if (!permission?.id) {
      return;
    }
    if (isChecked) {
      queryClient.setQueryData(["permissionGroupListById"], {
        ...permissionGroupDataById,
        permissions: [...permissionGroupDataById.permissions, permission.id],
      });
    } else {
      queryClient.setQueryData(["permissionGroupListById"], {
        ...permissionGroupDataById,
        permissions: permissionGroupDataById.permissions.filter(
          (id: any) => id !== permission.id
        ),
      });
    }
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {permissionGroupByIdLoading ||
        permissionLoading ||
        (permissionGroupByIdRefetching && <LoadingScreen />)}
      <Card className="w-full lg:w-4/5 mx-auto mt-10">
        <Button
          onClick={() => navigate("/permission-group")}
          className="my-4 w-4/5"
          variant={"secondary"}
        >
          {language === "en" ? "Back" : "رجوع"}
        </Button>
        <CardHeader>
          <CardTitle>
            {language === "en"
              ? "Edit Permission Group"
              : "تعديل مجموعة الصلاحيات"}
          </CardTitle>
          {isScuccess && (
            <div className="text-green-500">
              {language === "en"
                ? "Permission Group Updated Successfully"
                : "تم تحديث مجموعة الصلاحيات بنجاح"}
            </div>
          )}
        </CardHeader>
        <CardContent className="text-start">
          <div
            className={`flex justify-between`}
          >
            <Button
              onClick={() => setIsEdit(!isEdit)}
              className="my-4"
              variant={isEdit ? "destructive" : "default"}
              permission="change_group"
            >
              {isEdit
                ? language === "en"
                  ? "Cancel"
                  : "إلغاء"
                : language === "en"
                  ? "Edit"
                  : "تعديل"}
            </Button>
            <Dialog>
              <DialogTrigger>
                <Button
                  className="my-4"
                  variant="destructive"
                  permission="delete_group"
                >
                  {language === "en" ? "Delete" : "حذف"}
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>
                    {language === "en"
                      ? "Delete Permission Group"
                      : "حذف مجموعة الصلاحيات"}
                  </DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  {language === "en"
                    ? "Are you sure you want to delete this permission group?"
                    : "هل أنت متأكد أنك تريد حذف مجموعة الصلاحيات هذه؟"}
                </DialogDescription>
                <DialogFooter>
                  <Button
                    onClick={() => handlePermissionDelete()}
                    className="w-1/2"
                    variant="destructive"
                    permission="delete_group"
                  >
                    {language === "en" ? "Delete" : "حذف"}
                  </Button>
                  <Button className="w-1/2" variant="secondary">
                    {language === "en" ? "Cancel" : "إلغاء"}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>

          <CardDescription>
            <div className="mx-auto">
              <Label>
                {language === "en"
                  ? "Permission Group Name"
                  : "اسم مجموعة الصلاحيات"}
              </Label>
              <Input
                type="text"
                placeholder={
                  language === "en"
                    ? "Permission Group Name"
                    : "اسم مجموعة الصلاحيات"
                }
                value={permissionGroupDataById?.name}
                disabled={!isEdit}
                onChange={(e) => {
                  queryClient.setQueryData(["permissionGroupListById"], {
                    ...permissionGroupDataById,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full lg:w-4/5 mx-auto mt-4">
              <Label>{language === "en" ? "Permissions" : "الصلاحيات"}</Label>

              {/* Permissions Table */}
              <Table
                dir={language === "en" ? "ltr" : "rtl"}
                className="text-start"
              >
                <TableCaption>
                  {language === "en" ? "Permissions Table" : "جدول الصلاحيات"}
                </TableCaption>
                <TableHeader
                  className="text-start"
                >
                  <TableRow
                    className="text-start w-full"
                  >
                    <TableHead className="text-start">{language === "en" ? "Endpoint" : "نقطة النهاية"}</TableHead>
                    <TableHead className="text-start">{language === "en" ? "View" : "عرض"}</TableHead>
                    <TableHead className="text-start">{language === "en" ? "Add" : "إضافة"}</TableHead>
                    <TableHead className="text-start">{language === "en" ? "Change" : "تعديل"}</TableHead>
                    <TableHead className="text-start">{language === "en" ? "Delete" : "حذف"}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {permissionData &&
                    Object.keys(permissionData).map((endpoint: string) => (
                      <TableRow key={endpoint}>
                        <TableCell>{permissionData[endpoint]?.title}</TableCell>

                        {/* View permission */}
                        <TableCell>
                          <Checkbox
                            className="m-auto"
                            checked={!!permissionGroupDataById?.permissions.includes(
                              permissionData[endpoint].view?.id
                            )}
                            disabled={!isEdit}
                            onCheckedChange={(e) =>
                              handlePermissionChange(e, permissionData[endpoint].view)
                            }
                          />
                        </TableCell>

                        {/* Add permission */}
                        <TableCell>
                          <Checkbox
                            checked={!!permissionGroupDataById?.permissions.includes(
                              permissionData[endpoint].add?.id
                            )}
                            disabled={!isEdit}
                            onCheckedChange={(e) =>
                              handlePermissionChange(e, permissionData[endpoint].add)
                            }
                          />
                        </TableCell>

                        {/* Change permission */}
                        <TableCell>
                          <Checkbox
                            checked={!!permissionGroupDataById?.permissions.includes(
                              permissionData[endpoint].change?.id
                            )}
                            disabled={!isEdit}
                            onCheckedChange={(e) =>
                              handlePermissionChange(e, permissionData[endpoint].change)
                            }
                          />
                        </TableCell>

                        {/* Delete permission */}
                        <TableCell>
                          <Checkbox
                            checked={!!permissionGroupDataById?.permissions.includes(
                              permissionData[endpoint].delete?.id
                            )}
                            disabled={!isEdit}
                            onCheckedChange={(e) =>
                              handlePermissionChange(e, permissionData[endpoint].delete)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </CardDescription>
        </CardContent>
        <CardFooter>
          <Button
            onClick={handelSubmit}
            className="my-4 w-4/5 mx-auto"
            disabled={isSubmit}
            permission="change_group"
          >
            {isSubmit ? <LoadingIcon /> : language === "en" ? "Save" : "حفظ"}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PermissionGroupEdit;
