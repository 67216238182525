import { MdOutlineDarkMode } from "react-icons/md";
import { Switch } from "@/components/ui/switch"
import { useTheme } from "@/components/theme-provider"

export function ModeToggle() {
  const { setTheme, theme } = useTheme()

  const switchTheme = () => {
    if (theme === "light") {
      setTheme("dark")
    } else {
      setTheme("light")
    }
  }    

  return (
    <div className="flex items-center space-x-2 ">
      <Switch 
        id="mode-toggle"
        onCheckedChange={switchTheme}
        checked={theme === "dark"}
      />
      <MdOutlineDarkMode size={20}/>
    </div>
  )
}
