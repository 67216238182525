import { useLanguage } from "@/components/context/LanguageContext";
import LoadingIcon from "@/components/loading/loading-icon";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardTitle, CardDescription, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { submitNewPassword } from "@/services/user-service";
import { Label } from "@radix-ui/react-label";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

const SetNewPassword = () => {
    const { language, changeLanguage } = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const [userInfo, setUserInfo] = useState<any>({
        password: '',
        confirmPassword: '',
        uid: '',
        token: '',
        email: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    useEffect(() => {
        getParams();
        document.title = 'Lazem GO - Set New Password';
    }, []);

    const getParams = () => {
        const search = new URLSearchParams(location.search);
        const uid = search.get('uid');
        const token = search.get('token');
        const email = search.get('email');
        if (uid && token) {
            setUserInfo({
                ...userInfo,
                uid: uid || '',
                token: token || '',
                email: email || '',
            });
        } else {
            navigate('/forgot-password');
        }
    }

    const handleChange = (e:any) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const handleSetNewPassword = async () => {
        if (checkFields()) {
            setLoading(true);
            let data = {
                new_password: userInfo.password,
                uid: userInfo.uid,
                token: userInfo.token
            }
            await submitNewPassword(data).then((response) => {
                navigate('/login');
            }).catch((error) => {
                if (error.response){
                    console.log('Error', error.response.data);
                    let messages:any = []
                    Object.keys(error.response.data).forEach((key) => {
                        messages.push(error.response.data[key]);
                    });
                    if (messages.length > 0) {
                        setError(messages.join(', '));
                    }
                }
                else {
                    setError(language === 'en' ? 'Something went wrong' : 'حدث خطأ ما');
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const checkFields = () => {
        if (!userInfo.password || !userInfo.confirmPassword) {
            setError(language === 'en' ? 'Please fill all fields' : 'يرجى ملء جميع الحقول');
            return false;
        }
        if (userInfo.password !== userInfo.confirmPassword) {
            setError(language === 'en' ? 'Passwords do not match' : 'كلمات المرور غير متطابقة');
            return false;
        }
        setError('');
        return true;
    }

    return (
        <div 
            className="flex justify-center items-center h-screen"
            dir={language === 'en' ? 'ltr' : 'rtl'}
        >
            <Card 
                className="w-96 sm:w-96 md:w-96 lg:w-96 xl:w-96 2xl:w-96 p-4 text-start"
            >
                <CardContent>
                    <div className="flex my-4">
                        <Button 
                            onClick={() => changeLanguage(language === 'en' ? 'ar' : 'en')} 
                            className="w-20 h-8"
                        >
                            {language === 'en' ? 'العربية' : 'English'}
                        </Button>
                    </div>

                    <CardTitle
                        className="text-2xl font-bold text-center"
                    >
                        {language === 'en' ? 'Set New Password for' : ' تعيين كلمة مرور جديدة ل '}
                        <Label 
                            className="text-blue-500"
                        >
                            {userInfo.email}
                        </Label>
                    </CardTitle>
                    <CardDescription
                        className="text-center mt-2"
                    >
                            {language === 'en' ? 'Please enter your new password' : 'الرجاء إدخال كلمة المرور الجديدة'}
                    </CardDescription>
                    <div className="mt-4">
                        <Label>{language === 'en' ? 'Password' : 'كلمة المرور'}</Label>
                        <Input type="password" name="password" value={userInfo.password} onChange={handleChange} />
                    </div>
                    <div className="mt-4">
                        <Label>{language === 'en' ? 'Confirm Password' : 'تأكيد كلمة المرور'}</Label>
                        <Input type="password" name="confirmPassword" value={userInfo.confirmPassword} onChange={handleChange} />
                    </div>
                    <div 
                        className="mt-4 text-start text-sm font-normal space-x-1"
                    >
                        <PasswordChecklist
				            rules={["minLength","specialChar","number","capital","match"]}
                            value={userInfo.password}
                            valueAgain={userInfo.confirmPassword}
                            minLength={8}
                            iconSize={12}
                            validColor="green"
                            invalidColor="red"
                            validTextColor="green"
                            invalidTextColor="red"
                            rtl={language === 'ar'}
                            messages={{
                                minLength: language === 'en' ? 'Minimum 8 characters' : 'الحد الأدنى 8 أحرف',
                                specialChar: language === 'en' ? 'At least 1 special character' : 'على الأقل 1 حرف خاص',
                                number: language === 'en' ? 'At least 1 number' : 'على الأقل 1 رقم',
                                capital: language === 'en' ? 'At least 1 capital letter' : 'على الأقل 1 حرف كبير',
                                match: language === 'en' ? 'Passwords do not match' : 'كلمات المرور غير متطابقة',
                            }}
                            onChange={(isValid: boolean) => {
                                setIsPasswordValid(isValid);
                                if (isValid) {
                                    setError('');
                                    setIsPasswordValid(true);
                                } 
                            }}
                        />
                    </div>
                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                </CardContent>
                <CardFooter>
                    <Button 
                        onClick={handleSetNewPassword} 
                        className="w-full" 
                        disabled={loading || !isPasswordValid}

                    >
                        {loading ? <LoadingIcon /> : language === 'en' ? 'Set New Password' : 'تعيين كلمة مرور جديدة'}
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}

export default SetNewPassword;
