import { users } from "@/services/user-service";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/components/context/LanguageContext";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "@/components/loading/loading-screen";
import { Button } from "@/components/ui/button";

const OffboardingDashboard = () => {

    const { language } = useLanguage();
    const navigate = useNavigate();

    const { 
            data: usersData,
            isLoading: usersLoading, 
        } = useQuery({
        queryKey: ["users"],
        queryFn: users.simple,
        refetchOnWindowFocus: false,
    });

  return (
    <div
    >
        {usersLoading && <LoadingScreen />}
        <div>
            <h1 className="text-3xl font-bold text-center m-auto mt-10">
                {language === 'en' ? "Select a user to offboard" : "اختر مستخدمًا لإنهاء العمل معه"}
            </h1>

            <div className="flex justify-end items-end mx-6">
                <Button
                    onClick={() => navigate("/special-request/")}
                >
                    {language === 'en' ? "Back" : "رجوع"}
                </Button>
            </div>

        </div>
        <div
            className="flex flex-wrap mx-auto mt-10 gap-6 p-4 justify-content-center"
        >

            {usersData && usersData?.data?.map((user:any, index:any) => (  
                <div
                    key={index}
                    className="m-auto"
                >
                    {
                        <Card
                            className="m-3 w-40 h-40 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                            onClick={() => navigate(`/special-request/offboarding/id/${user.id}`)}
                        >
                            <CardHeader
                            >
                                <CardTitle
                                    className="text-xl font-bold flex justify-content-center m-auto text-center w-full"
                                >
                                    {user.full_name}
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    }                
                </div> 
            ))    
            }
        </div>
    </div>
  );
}

export default OffboardingDashboard;