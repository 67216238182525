import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { format, toZonedTime } from 'date-fns-tz';
import { useLanguage } from "./context/LanguageContext";

export default function LogFeed(
  { logs }: any
) {
  const { language } = useLanguage();
  const convertDate = (date: any) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Riyadh';
    let formattedDateTime = format(toZonedTime(date, timeZone), 'MMMM d, yyyy hh:mm a', { timeZone });
    return formattedDateTime;
  }
  return (
    <div
      className="w-full max-w-2xl mx-auto mt-12 p-2"
      dir={language === "en" ? "ltr" : "rtl"}
    >
      <ul
        aria-label="User feed"
        role="feed"
        className="relative flex flex-col gap-12 py-12 pl-6 before:absolute before:top-0 before:left-6 before:h-full before:-translate-x-1/2 before:border before:border-dashed before:border-slate-200 after:absolute after:top-6 after:left-6 after:bottom-6 after:-translate-x-1/2 after:border after:border-slate-200 "
      >
        {logs?.map((log: any) => (
          <li role="article" className="relative pl-6 ">
            <div className="flex flex-col flex-1 gap-2">
              <div
                className="absolute z-10 inline-flex items-center justify-center w-6 h-6 text-white rounded-full -left-3 ring-2 ring-white bg-green-500"
              >

              </div>
              <h4 className="flex flex-col items-start text-base font-medium leading-6 md:flex-row lg:items-center">
                <Label className={"flex-1" + (log?.is_system ? " text-cyan-800 dark:text-cyan-200" : "")}>
                  {log?.is_system ? "GO System" : log?.created_by?.full_name}
                </Label>
                <Label className="text-xs font-normal">
                  {" "}
                  {convertDate(log.created_at)}
                </Label>
              </h4>
              <Label className="text-sm font-normal w-full text-end">
                {" "}
                {log.comment}
              </Label>
              <p
                className="text-sm flex flex-col justify-start items-start font-normal"
              >{
                  log?.attachments?.map((attachment: any) => (
                    <Button
                      key={attachment.id}
                      onClick={() => window.open(attachment.file, "_blank")}
                      variant={"link"}
                      className="text-sm font-normal text-blue-500 text"
                    >
                      {attachment.file.split("/").pop().split("?")[0]}
                    </Button>
                  ))
                }
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
