import { EmailLogs } from "@/services/log-services";
import { useQuery } from "@tanstack/react-query";
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from "@/components/ui/pagination";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingScreen from "@/components/loading/loading-screen";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { format, toZonedTime } from 'date-fns-tz';

const EmailsLogsDashboard = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { language } = useLanguage();
    const [currentPage, setCurrentPage] = useState<any>(null);
    const [pageCount, setPageCount] = useState<any>(0);

    useEffect(() => {
        const pageParam = searchParams.get("page");
        if (pageParam) {
            setCurrentPage(pageParam);
        }
    }, [searchParams]);
    const convertDate = (date: any) => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Riyadh';
        let formattedDateTime = format(toZonedTime(date, timeZone), 'MMMM d, yyyy hh:mm a', { timeZone });
        return formattedDateTime;
      }
    const {
        data: logsData,
        isLoading: logsLoading,
        refetch: refetchLogs,
        isFetching: logsFetching,
    } = useQuery({
        queryKey: ["email logs", currentPage, searchParams],
        queryFn: () =>
            EmailLogs.list(currentPage).then((response) => {
                let count = (response?.count / response?.results?.length).toFixed(0);
                setPageCount(count);
                return response;
            }),
        refetchOnWindowFocus: false,
    });

    const handleNextPage = () => {
        if (logsData?.next) {
            searchParams.set("page", logsData.next.split("=")[1]);
            navigate(`?${searchParams.toString()}`);
            setCurrentPage(logsData.next);
            refetchLogs();
        }
    };

    const handlePreviousPage = () => {
        if (logsData?.previous) {
            searchParams.set("page", logsData.previous.split("=")[1] || 1);
            navigate(`?${searchParams.toString()}`);
            setCurrentPage(logsData.previous);
            refetchLogs();
        }
    };

    const handleSearch = (page: any) => {
        searchParams.set("page", page);
        navigate(`?${searchParams.toString()}`);
        setCurrentPage(page);
        refetchLogs();
    }
    const handleHeaders = (headers: any) => {
        return (Object.entries(headers).map(([key, head]: any) => (
            <div className="flex flex-col">
                <span className="text-sm font-semibold">{key}</span>
                <span className="text-xs">{head}</span>
            </div>
        )))
    }
    const handleLogsRows = (logs: any) => {
        const results = logs?.results;
        return (
            results?.map((log: any) => (

                <tr 
                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                    onClick={() => {
                        navigate(`/email-logs/id/${log.id}`);
                      }}
                >
                    <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-normal break-words max-w-xs"
                    >
                        {log?.id}
                    </th>
                    <td className="px-6 py-4 whitespace-normal break-words max-w-xs">
                        {convertDate(log?.created_at || null)}
                    </td>
                    <td className="px-6 py-4 whitespace-normal break-words max-w-xs">
                        {log?.sender}
                    </td>
                    <td className="px-6 py-4 whitespace-normal break-words max-w-xs">
                        {
                            log?.sent_to?.map((email: any) => (
                                <span>{email + " "}</span>
                            ))
                        }
                    </td>
                    <td className="px-6 py-4 whitespace-normal break-words max-w-xs">
                        {log?.sent_from}
                    </td>
                    <td className="px-6 py-4 whitespace-normal break-words max-w-xs">
                        {log?.status}
                    </td>
                    <td className="px-6 py-4 whitespace-normal break-words max-w-xs">
                        {log?.subject}
                    </td>
                    <td className="px-6 py-4 whitespace-normal break-words max-w-xs">
                        {log?.trigger}
                    </td>
                </tr>

            ))
        )
    };

    return (
        <div>
            {(logsLoading || logsFetching) && <LoadingScreen />}
            <h1 className="text-3xl font-bold text-center my-4">
                {language === "en" ? "Email Logs" : "سجلات البريد الإلكتروني"}
            </h1>
            {logsData && (
                <Pagination>
                    <PaginationPrevious
                        onClick={handlePreviousPage}
                        isActive={logsData.previous !== null}
                    >
                        {language === "en" ? "Previous" : "السابق"}
                    </PaginationPrevious>
                    <PaginationContent>
                        {logsData.previous && logsData.previous !== null && (
                            <PaginationItem>
                                <PaginationLink
                                    onClick={() => {
                                        handleSearch(Number(logsData?.previous?.split("=")[1]) - 1 || 1);
                                    }}
                                >
                                    {Number(logsData.previous.split("=")[1]) - 1 || 1}
                                </PaginationLink>
                            </PaginationItem>
                        )}
                        <PaginationItem>
                            <PaginationLink
                                onClick={() => {
                                    handleSearch(logsData.previous.split("=")[1]);
                                }}
                            >
                                {(logsData.previous && logsData.previous !== null) &&
                                    logsData.previous.split("=")[1]
                                }
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationEllipsis />
                        {logsData.next && logsData.next.split("=")[1] !== pageCount && (
                            <PaginationItem>
                                <PaginationLink
                                    onClick={() => {
                                        handleSearch(logsData.next.split("=")[1]);
                                    }}
                                >
                                    {logsData.next.split("=")[1]}
                                </PaginationLink>
                            </PaginationItem>
                        )}
                        {
                            (logsData?.count !== currentPage) &&

                            <PaginationItem>
                                <PaginationLink
                                    onClick={() => {
                                        handleSearch(pageCount);
                                    }}
                                >
                                    {pageCount}
                                </PaginationLink>
                            </PaginationItem>
                        }
                    </PaginationContent>
                    <PaginationNext
                        onClick={handleNextPage}
                        isActive={logsData.next !== null}
                    >
                        {language === "en" ? "Next" : "التالي"}
                    </PaginationNext>
                </Pagination>
            )}
            <div className="flex justify-center items-center my-4 w-2/5 mx-auto ">
                <Input
                    type="text"
                    placeholder={language === "en" ? "Search by page" : "البحث بالصفحة"}
                    onKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                            handleSearch(e.target.value);
                        }
                    }}
                />
            </div>
            <div>


                <div className="relative shadow-md sm:rounded-lg max-w-full overflow-x-scroll">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Id
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Sender
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Recipients
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Sent From
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Subject
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Trigger
                                </th>
                            </tr>
                        </thead>
                        <tbody className="max-w-full">
                            {handleLogsRows(logsData)}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
};

export default EmailsLogsDashboard;
